import moment from 'moment';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Button, Checkbox, TableFooter, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { convertCurrency } from 'src/utils/utils';
import errorImg from 'src/assets/img/error-img.png';
import { checkNameBranch, checkNameHeader, checkStatusWarehouse } from 'src/functions/expand-header';
import { headerChild, rowChild } from 'src/utils/app-data';
import noData from 'src/assets/no-data.png';
import './index.scss';
import React from 'react';

function MuiTableRow(props: any) {
  const {
    row,
    length,
    selectedRow,
    onEditNote,
    handleChangeNote,
    selectedChildRow,
    selectedItemChild,
    onShowDetail,
    text,
    changeTextNote,
    handelShowUpdateSku,
    selected,
    onSelectAllClick,
    skuProductId,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [exampleState, setExampleState] = useState<string>(row?.note);
  const onChange = (event: any) => {
    setExampleState(event.target.value);
    text(event.target.value);
  };

  const isSelected = (name: string) => {
    return selected?.indexOf(name) !== -1;
  };
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  return (
    <>
      <TableRow
        hover
        key={row?.id}
        sx={{
          '& .MuiTableBody-root:hover': {
            backgroundColor: '#76B8CD1A',
          },
          '& .MuiTableRow-selected': {
            backgroundColor: '#76b8cd1a',
          },
        }}
        onClick={() => {
          selectedRow(row);
        }}

      // selected={checkSelectedRow()}
      >
        <TableCell
          align="center"
          component="th"
          scope="row"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {length + 1}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            handelShowUpdateSku(row.id);
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              textDecorationLine: 'underline',
              color: '#1A89AC',
            }}
          >
            {row?.sku}
          </div>
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {row?.brand}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {row?.type?.value}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="none"
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
          style={{ padding: '4px' }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={row.images?.[0]}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              style={{ aspectRatio: 1, width: '65px', padding: '2px' }}
            />
          </div>
        </TableCell>
        <TableCell
          align="left"
          style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {row.name}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                color: checkStatusWarehouse(row.status)?.titleColor,
                backgroundColor: checkStatusWarehouse(row.status)?.backgroundColor,
                borderRadius: '10px',
                display: 'flex',
                width: '4.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                height: '1.5rem',
              }}
            >
              {checkStatusWarehouse(row.status)?.status}
            </div>
          </div>
        </TableCell>
        <TableCell
          align="center"
          style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {row.quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {row.quantitySold}
        </TableCell>
        {!isEmpty(user) && user?.roles?.includes(1) ? (
          <TableCell
            align="center"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {convertCurrency(row.totalPrice)}
          </TableCell>
        ) : (
          <></>
        )}
        <TableCell align="center">
          <TextField
            value={exampleState}
            onChange={onChange}
            className="input-field font-size-1 text-color"
            multiline
            maxRows={3}
            variant="outlined"
          />
        </TableCell>
        <TableCell align="center">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button style={{ backgroundColor: 'transparent' }} onClick={() => onEditNote(row?.id)}>
              <DoneIcon />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            width: '100%',
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Table sx={{ minWidth: 1050 }} size="small" aria-label="a dense table" padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '2rem' }}>
                      {'STT'}
                    </TableCell>
                    {headerChild?.map((item: any, index: number) => {
                      if (!isEmpty(user) && !user?.roles?.includes(1) && item.id === 7) {
                        return;
                      }
                      return (
                        <TableCell align="center" style={{ width: item?.flex }} key={item?.id + index}>
                          {item.name}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      style={{ width: 2 }}
                      sx={{
                        '&.MuiTableCell-root': {
                          paddingX: '0.2rem !important',
                          paddingY: '0.1rem !important',
                        },
                      }}
                      padding="checkbox"
                      align="center"
                    >
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected &&
                          selected?.length > 0 &&
                          selected.length < row?.products.length &&
                          skuProductId === row?.id
                        }
                        checked={
                          selected &&
                          row?.products?.length > 0 &&
                          selected.length === row?.products.length &&
                          skuProductId === row?.id
                        }
                        onChange={() => onSelectAllClick(row?.products)}
                        // onClick={() => onSelectAllClick(row?.products)}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        size="medium"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.products?.map((childItem: any, index: number) => {
                    // const findSelected = selectedItemChild.find((sku: any) => sku === childItem?.id);
                    // const isSelectedItem = !isEmpty(findSelected) ? true : false;
                    const isSelectedItem = isSelected(childItem?.id);
                    return (
                      <TableRow key={childItem?.id}>
                        <TableCell
                          align="center"
                          style={{ width: '3rem' }}
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                        >
                          {childItem?.sku}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                        >
                          {checkNameBranch(childItem?.branch)?.branch}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                          style={{ padding: '4px' }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={childItem.images?.[0]}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = errorImg;
                              }}
                              alt=""
                              style={{ aspectRatio: 1, width: '65px', padding: '2px' }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="left"
                        >
                          <div
                            style={{
                              flexDirection: 'column',
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            {childItem?.variations.map((item: any, index: number) => {
                              if (!isEmpty(item.value)) {
                                return (
                                  <div>
                                    <span key={index} style={{ fontWeight: '600' }}>
                                      {item.name}
                                    </span>
                                    <span key={index}>: {item.value}</span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          className="col-amount"
                          align="center"
                        >
                          {childItem?.quantity}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                        >
                          {childItem?.discountValue}
                        </TableCell>
                        {/* {!isEmpty(user) && user?.roles?.includes(1) && (
                          <TableCell
                            onClick={() => {
                              onShowDetail(childItem?.id);
                            }}
                            align="center"
                          >
                            {convertCurrency(childItem?.entryPrice)}
                          </TableCell>
                        )} */}
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          className="col-amount"
                          align="center"
                        >
                          {childItem?.quantitySold}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                        >
                          {convertCurrency(childItem?.price)}
                        </TableCell>

                        <TableCell align="center">{convertCurrency(childItem?.priceReal)}</TableCell>
                        <TableCell align="center">
                          {childItem?.warehouseDate && moment(childItem?.warehouseDate)?.format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                        >
                          {childItem?.warehouseCheckDate && moment(childItem?.warehouseCheckDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            onShowDetail(childItem?.id);
                          }}
                          align="center"
                        >
                          {childItem?.note}
                        </TableCell>
                        <TableCell
                          sx={{
                            '&.MuiTableCell-root': {
                              paddingX: '0.2rem !important',
                              paddingY: '0.1rem !important',
                            },
                          }}
                          align="center"
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                            className="checkbox-width"
                          >
                            <Button
                              onClick={() => {
                                selectedChildRow(childItem);
                              }}
                              style={{ backgroundColor: 'transparent' }}
                            >
                              {isSelectedItem ? (
                                <CheckBoxIcon />
                              ) : (
                                <CheckBoxOutlineBlankIcon
                                  style={{
                                    color: '#999999',
                                  }}
                                />
                              )}
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface expandProps {
  columns: any;
  rows: any;
  selectedRow: any;
  onEditNote: any;
  handleChangeNote?: any;
  selectedChildRow: any;
  selectedItemChild: any;
  total: number;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  page: number;
  pageSize: number;
  onShowDetail?: any;
  refetch?: any;
  changeTextNote?: string;
  text?: any;
  handelShowUpdateSku?: any;
  selected?: any;
  onSelectAllClick?: any;
  skuProductId?: any;
}

function CollapsibleTable(props: expandProps) {
  const [dataRow, setDataRow] = useState<any>();

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    columns,
    rows,
    selectedRow,
    onEditNote,
    handleChangeNote,
    selectedChildRow,
    selectedItemChild,
    total,
    pageSize,
    page,
    onShowDetail,
    changeTextNote,
    text,
    handelShowUpdateSku,
    selected,
    onSelectAllClick,
    skuProductId,
  } = props;

  useEffect(() => {
    setDataRow(rows);
  }, [rows]);

  return (
    <>
      <TableContainer component={Paper} className="expand-table">
        <Table sx={{ minWidth: 1050 }} size="small" aria-label="a dense table" padding="none">
          <TableHead>
            <TableRow key={'123'}>
              {columns?.map((col: any, i: number) => {
                return (
                  <TableCell align={'center'} key={col.id + i} style={{ width: col?.flex }}>
                    {col.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow?.map((row: any, index: number) => (
              <MuiTableRow
                key={row.id + index}
                row={row}
                length={index}
                selectedRow={selectedRow}
                onEditNote={onEditNote}
                handleChangeNote={handleChangeNote}
                selectedChildRow={selectedChildRow}
                selectedItemChild={selectedItemChild}
                onShowDetail={onShowDetail}
                changeTextNote={changeTextNote}
                text={text}
                handelShowUpdateSku={handelShowUpdateSku}
                selected={selected}
                onSelectAllClick={onSelectAllClick}
                skuProductId={skuProductId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <p>Chuyển nhanh đến trang: </p>
        <input
          value={page + 1}
          min={1}
          onChange={(e) => {
            handleChangePage(e, Number(e.target.value) - 1);
          }}
          style={{ width: 50, marginLeft: 20, textAlign: 'center' }}
        />
        <p style={{ padding: '0px 12px' }}>Tổng số trang: {Math.ceil(total / pageSize)}</p>
      </div>
      {isEmpty(rows) && (
        <div className={'btn-add-product'}>
          <div className={'view-btn-add'}>
            <img src={noData} className="empty-data" />
            <span>Không có sản phẩm</span>
          </div>
        </div>
      )}
      <TablePagination
        className="shrink-0 "
        component="div"
        count={total || 0}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        rowsPerPageOptions={[10, 20, 30, 100]}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Số hàng hiển thị'}
      />
    </>
  );
}
export default React.memo(CollapsibleTable);
