import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import './index.scss';

interface IUploadImageProps {
  onChange: (value: ImageListType, addUpdatedIndex?: number[] | undefined) => void;
  images: ImageListType;
  icon?: boolean;
  onRemove: any;
}

const UploadIcon = (props: IUploadImageProps) => {
  const { onChange, images, icon = false, onRemove } = props;

  const maxNumber = 69;

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg', 'png']}
      >
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          <div
            className="upload__image-wrapper"
            style={{
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList?.map((image:any, index:any) => (
              <div key={index} className="image-item">
                <Button
                  className="close"
                  onClick={() => {
                    onImageRemove(index);
                    onRemove();
                  }}
                >
                  <CancelIcon />
                </Button>
                <img src={image?.data_url || image || []} alt="" width="100" />
              </div>
            ))}
            {imageList?.length == 0 && (
              <div className={imageList?.length ? 'view-upload-length' : 'view-upload'}>
                <div
                  className="upload-image"
                  style={isDragging ? { color: 'red' } : {}}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <AddPhotoAlternateOutlinedIcon />
                </div>

                {!imageList?.length && <span className="txt-limit">** kích thước tối đa: 1MB</span>}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default UploadIcon;
