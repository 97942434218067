import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

interface ISnackbarProps {
  open: boolean;
  onClose: (data: any | undefined) => void;
  message: string;
  type: any;
}

const SnackbarCustom = (props: ISnackbarProps) => {
  const { open, onClose, message, type } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={onClose}
        severity={type}
        sx={{
          width: '100%',
          '& > .MuiAlert-message::first-letter': {
            textTransform: 'capitalize',
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarCustom;
