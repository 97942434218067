import { Button, Checkbox } from '@mui/material';

import './index.scss';
import { convertCurrency } from 'src/utils/utils';
import errorImg from 'src/assets/img/error-img.png';
import DataTableV2 from 'src/shared-components/DataTableV2';
import { status } from 'src/utils/app-data';
import { checkStatusWarehouse } from 'src/functions/expand-header';

const SKUList = (props: any) => {
  const {
    data,
    selectionModel,
    handleSelectionModeChange,
    handleShowDetail,
    handleClick,
    onChangeRowsPerPage,
    handleChangePage,
    total,
    page,
    handleInPreview,
    pageSize,
  } = props;

  const columns: any = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'sku',
      headerName: 'Mã SKU',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'images',
      headerName: 'Ảnh',
      flex: 70,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={params.row.images?.[0]}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              style={{ aspectRatio: 1, width: '60px', backgroundSize: 'cover' }}
            />
          </div>
        );
      },
    },
    {
      field: 'name',
      flex: 130,
      headerName: 'Tên sản phẩm',
      headerAlign: 'center',
      headerClassName: 'theme',
      renderCell: (params: any) => {
        return (
          <div style={{ marginLeft: '5px', marginRight: '5px', textAlign: 'start' }}>
            <div>{params?.row.name}</div>
          </div>
        );
      },
    },
    {
      field: 'brand',
      headerName: 'Thương hiệu',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Loại sản phẩm',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return <div>{params?.row?.type?.value}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Tình trạng',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        const itemStatus = checkStatusWarehouse(status.find(item => item?.codeName === params?.row?.status)?.codeName);

        return (
          <div style={{}}>
            <span
              style={{
                width: 'fit-content',
                color: itemStatus?.titleColor,
                backgroundColor: itemStatus?.backgroundColor,
                padding: '4px 8px',
                borderRadius: '25px',
              }}
            >
              {itemStatus?.status || 'Còn hàng'}
            </span>
          </div>
        );
      },
    },
    {
      field: 'total',
      headerName: 'Tồn kho',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return <div>{params?.row?.total || 0}</div>;
      },
    },
    {
      field: 'priceSi',
      headerName: 'Số lượng',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        const priceCTV = params?.row?.productWholesales?.find((item: any) => item.productSaleCode === 'CTV');
        const priceSI_1 = params?.row?.productWholesales?.find((item: any) => item.productSaleCode === 'SI_1');
        const priceSI_2 = params?.row?.productWholesales?.find((item: any) => item.productSaleCode === 'SI_2');

        return (
          <>
            <div>{priceCTV?.quantity ?? 0}</div>
            <div>{priceSI_1?.quantity ?? 0}</div>
            <div>{priceSI_2?.quantity ?? 0}</div>
          </>
        );
      },
    },
    {
      field: 'priceCTV',
      headerName: 'Giá giảm',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        const priceCTV = params?.row?.productWholesales?.find((item: any) => item.productSaleCode === 'CTV');
        const priceSI_1 = params?.row?.productWholesales?.find((item: any) => item.productSaleCode === 'SI_1');
        const priceSI_2 = params?.row?.productWholesales?.find((item: any) => item.productSaleCode === 'SI_2');

        return (
          <>
            <div>{convertCurrency(priceCTV?.price ?? 0)}</div>
            <div>{convertCurrency(priceSI_1?.price ?? 0)}</div>
            <div>{convertCurrency(priceSI_2?.price ?? 0)}</div>
          </>
        );
      },
    },
    {
      field: 'note',
      headerName: 'Ghi chú',
      flex: 220,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    // {
    //   field: 'action',
    //   headerName: 'Bán hàng',
    //   flex: 70,
    //   headerAlign: 'center',
    //   headerClassName: 'theme',
    //   sortable: false,
    //   renderCell: (params: any) => {
    //     return (
    //       <div style={{ backgroundColor: 'transparent' }}>
    //         <Checkbox
    //           size="small"
    //           color="primary"
    //           defaultChecked={!params?.row?.inPreview}
    //           inputProps={{
    //             'aria-labelledby': 'check-box-',
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <DataTableV2
      rows={data}
      columns={columns}
      {...props}
      checkboxSelection
      handleSelectAllClick={handleSelectionModeChange}
      handleClick={handleClick}
      selected={selectionModel}
      handleShowDetail={handleShowDetail}
      handleChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      total={total}
      page={page}
      pageSize={pageSize}
      handleInPreview={handleInPreview}
      isSell={true}
    />
  );
};

export default SKUList;
