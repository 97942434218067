import * as yup from 'yup';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Checkbox, Grid, IconButton, InputAdornment, Link } from '@mui/material';
import { RiErrorWarningLine, RiEye2Line, RiEyeCloseLine } from 'react-icons/ri';

import './login.scss';
import { login } from 'src/services';
import logo from 'src/assets/logo.png';
import imgLogin from 'src/assets/imgLogin.png';
import { ILoginRequest } from 'src/model/login.model';
import { useToast } from 'src/shared-components/Toast';
import InputField from 'src/shared-components/InputField';
import LoadingCircle from 'src/shared-components/LoadingCircle';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Vui lòng nhập tài khoản')
    .min(6, 'Chỉ được phép chứa số và kí tự không dấu, và gồm ít nhất 6 kí tự'),
  password: yup.string().required('Vui lòng nhập mật khẩu').min(6, 'Mật khẩu gồm ít nhất 6 kí tự'),
});

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [togglePass, setTogglePass] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleClickShowPassword = () => {
    setTogglePass(prev => !prev);
  };

  const onSelect = () => {
    setSelected(!selected);
  };

  const mutation = useMutation({
    mutationFn: (params: ILoginRequest) => {
      return login(params).then(getData => {
        setLoading(true);
        const { access_token } = getData;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('user', JSON.stringify(getData?.user));
        toast('success', 'Đăng nhập thành công');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      });
    },
    onError: (error: any) => {
      toast('error', error?.response?.data?.message || 'Tài khoản hoặc mật khẩu không đúng ');
      setLoading(false);
    },
  });

  const onNextStepTwo = (values: any) => {
    const { username, password } = values;
    const params: ILoginRequest = { username, password, remember: selected ? 1 : 0 };
    mutation.mutate(params);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onNextStepTwo)}>
        <Grid container>
          <Grid className={'item-login'} md={5.5} xs={24}>
            <div className={'container'}>
              <img src={logo} alt="Logo" />
              <div className={'form-field'}>
                <div>
                  <InputField
                    className="input-field"
                    defaultValue={''}
                    name="username"
                    error={Boolean(errors?.username)}
                    InputProps={{
                      endAdornment: errors?.username && (
                        <InputAdornment position="end">
                          <IconButton edge="end" color="error">
                            <RiErrorWarningLine />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={errors?.username?.message}
                    placeholder="Nhập email đăng nhập"
                  />
                </div>
                <div>
                  <InputField
                    className="input-field"
                    defaultValue={''}
                    name="password"
                    type={togglePass ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: errors?.password ? (
                        <InputAdornment position="end">
                          <IconButton edge="end" color="error">
                            <RiErrorWarningLine />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {togglePass ? <RiEye2Line /> : <RiEyeCloseLine />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors?.password)}
                    helperText={errors?.password?.message}
                    placeholder="Nhập mật khẩu"
                  />
                </div>
                <div className={'view-link'}>
                  <Checkbox
                    color="primary"
                    checked={selected}
                    onChange={onSelect}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                    style={{ color: 'white' }}
                    size="small"
                  />
                  <div style={{ color: 'white' }}>Ghi nhớ </div>
                </div>
                <LoadingButton loading={loading} type="submit" className="btn-primary-hover btn-login">
                  Đăng nhập
                </LoadingButton>
              </div>
            </div>
          </Grid>
          {mutation.isLoading && <LoadingCircle />}
          <Grid md={6.5} className={'item-content-right'}>
            <div className="container-right">
              <div className="text-welcome">Chào mừng bạn quay lại</div>
              <h5 className="text">Vui lòng đăng nhập để sử dụng hệ thông</h5>
              <div>
                <img src={imgLogin} alt="img" className="image-right" />
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <SnackbarCustom
          open={open}
          message={message}
          onClose={onCloseSnackBar}
          type={(!['loading', 'light'].includes(mutation?.status) && mutation?.status) || 'error'}
        /> */}
      </form>
    </FormProvider>
  );
};

export default Login;
