import axios from 'axios';
import { logout } from 'src/services';
import { parseJwt } from 'src/shared-components/auth-verify';

const clientRequest = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

clientRequest.interceptors.request.use(
  (config: any) => {
    const auth = localStorage.getItem('access_token');

    if (config.headers && auth) {
      config.headers.Authorization = `Bearer ${auth}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

clientRequest.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // const auth = localStorage.getItem('access_token');
    // const decodedJwt = parseJwt(auth);
    // const { type } = decodedJwt;

    // if (error.response.data.message === 'Không tìm thấy tài khoản') {
    // 	authService.logoutUser();
    // 	window.location.href = '/login';
    // }
    if (error.response.status === 401) {
      logout();
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    } else if (error.response.status === 403) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  },
);

export default clientRequest;
