import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoadingButton } from '@mui/lab';
import { Button, Drawer, Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';
// import "./index.scss";

const ProductCreateAndEdit = (props: any) => {
  const { setShowDrawer, showDrawer, formType } = props;
  const schema = yup.object().shape({
    code: yup.string().required('Mã không được phép bỏ trống'),
    name: yup.string().required('Tên nhóm sản phẩm không được phép bỏ trống'),
  });

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState } = methods;
  const { errors } = formState;
  // const [togglePass, setTogglePass] = useState(false);
  // const toast = useToast();

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
  };

  const onSubmit = (value: any) => {};

  const getTitle = (type: string) => {
    if (type === 'CREATE') {
      return 'Thêm mới';
    } else {
      return 'Chỉnh sửa';
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={() => setShowDrawer(false)}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>{getTitle(formType)}</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Nhóm sản phẩm</span>
            </div>
            <div className="content">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControlCustom label={'Mã nhóm sản phẩm'} fullWidth>
                    <InputField
                      name={'code'}
                      placeholder={'Nhập mã'}
                      error={Boolean(errors.code)}
                      helperText={errors?.code?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Tên nhóm sản phẩm'} fullWidth>
                    <InputField
                      name={'name'}
                      placeholder={'Nhập tên nhóm sản phẩm'}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button
                  className="btn-secondary-disable"
                  onClick={() => {
                    setShowDrawer(false);
                    reset();
                  }}
                >
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <LoadingButton
                  className={!isEmpty(errors) ? 'btn-tertiary-disable' : 'btn-tertiary-normal'}
                  variant="outlined"
                  type="submit"
                >
                  {'Xác nhận'}
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default ProductCreateAndEdit;
