import clientRequest from 'src/config/axios';
import { CreateSkuRq } from 'src/model/sku.modal';

export const getSku = async (params: any) => {
  const res = await clientRequest.get(`productsku`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const createSku = async (params: CreateSkuRq) => {
  const res = await clientRequest.post(`productsku`, {
    ...params,
  });
  return res;
};

export const getDetailSku = async (params: any) => {
  const res = await clientRequest.get(`productsku/${params}`);
  return res;
};

export const updateSku = async (params: any) => {
  const res = await clientRequest.put(`productsku/${params.id}`, {
    ...params,
  });
  return res;
};

export const deleteSku = async (params: any) => {
  const res = await clientRequest.delete(`productsku`, {
    data: {
      ...params,
    },
  });
  return res;
};

export const importSku = async (params: any) => {
  const res = await clientRequest.post(`importsku`, params);
  return res;
};
