import { GridColDef } from '@mui/x-data-grid';
import DataTable from 'src/shared-components/DataTable';

const AttributeList = (props: any) => {
  const {
    data,
    // handlePageChange,
    // isFetching,
    // pagination,
    // handleRowsPerPageChange,
    // selectionModel,
    // handleSelectionModeChange,
    // handleShowDetail,
    // listFilterChecked,
    // userStatusesQuery,
  } = props;

  const columns: GridColDef[] = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Mã thương hiệu',
      flex: 30,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'name',
      flex: 150,
      headerName: 'Tên thương hiệu',
      headerAlign: 'center',
      headerClassName: 'theme',
    },
    // {
    //   field: "action",
    //   flex: 150,
    //   headerName: "Thao tác",
    //   headerAlign: "center",
    //   headerClassName: "theme",
    //   renderCell: () => {
    //     return (
    //       <Button>Edit</Button>
    //     )
    //   },
    // }
  ];
  return (
    <DataTable
      // pagination
      // loading={isFetching}
      // page={pagination.page}
      // paginationMode="server"
      rows={data}
      checkboxSelection
      disableSelectionOnClick
      // keepNonExistentRowsSelected
      // pageSize={pagination.pageSize}
      // selectionModel={selectionModel}
      // onPageChange={handlePageChange}
      // rowsPerPageOptions={[5, 10, 20]}
      // rowCount={data?.pagination.total}
      // onPageSizeChange={handleRowsPerPageChange}
      // onRowDoubleClick={(params) => handleShowDetail(params.id, 'DETAIL')}
      // onSelectionModelChange={(newSelectionModel:any) => handleSelectionModeChange(newSelectionModel)}
      columns={columns}
    />
  );
};

export default AttributeList;
