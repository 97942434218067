import { Button, TextField } from '@mui/material';

import './index.scss';
import DataTableV2 from 'src/shared-components/DataTableV2';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteConfigBrand, editConfigBrand } from 'src/services/app.services';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
// import edit from 'src/assets/edit.jpg';
import trash from 'src/assets/trash.jpg';
import { AiOutlineCheckSquare } from 'react-icons/ai';

const BrandList = (props: any) => {
  const {
    data,
    selectionModel,
    handleSelectionModeChange,
    handleShowDetail,
    handleClick,
    onChangeRowsPerPage,
    handleChangePage,
    total,
    page,
    pageSize,
    handleOpenModal,
    text,
    refetch,
  } = props;
  const toast = useToast();

  const [exampleState, setExampleState] = useState<string>();
  const debouncedSearch = useDebounce(exampleState, 500);

  const mutationDeleteBrand = useMutation(deleteConfigBrand, {
    onSuccess: (data: any) => {
      toast('success', 'Xóa thương hiệu thành công');
      refetch();
    },
    onError: (error: any) => {
      toast('error', error.response.data.message);
    },
  });
  const mutationUpdateBrand = useMutation({
    mutationFn: editConfigBrand,
    onSuccess: (data: any) => {
      toast('success', 'Cập nhật thành công');
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });
  const handleDeleteBrand = (item: any) => {
    const params = {
      brandIds: [item?.id],
    };
    mutationDeleteBrand.mutate(params);
  };
  const onShowDetail = (item: any) => {
    const params = {
      id: item?.id,
      currentBrand: item.name,
      value: debouncedSearch,
    };
    mutationUpdateBrand.mutate(params);
  };

  const columns: any = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },

    {
      field: 'brand',
      headerName: 'Tên thương hiệu',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        const onChange = (event: any) => {
          setExampleState(event.target.value);
          text(event.target.value);
        };
        return (
          <TextField
            value={params.row?.name}
            onChange={onChange}
            className="input-field font-size-1 text-color"
            multiline
            maxRows={3}
            variant="outlined"
            style={{ margin: '10px' }}
          />
        );
      },
    },

    {
      field: 'action',
      headerName: '',
      flex: 10,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Button onClick={() => onShowDetail(params?.row)} style={{ backgroundColor: 'transparent' }}>
              {/* <img src={edit} style={{ aspectRatio: 1, width: '23px', backgroundSize: 'cover' }} /> */}
              <AiOutlineCheckSquare size={23} color="#1397BE" />
            </Button>
            <Button onClick={() => handleDeleteBrand(params?.row)} style={{ backgroundColor: 'transparent' }}>
              <img src={trash} style={{ aspectRatio: 1, width: '23px', backgroundSize: 'cover' }} />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <DataTableV2
      rows={data}
      columns={columns}
      {...props}
      handleChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleShowDetail={() => {}}
      total={total}
      page={page}
      pageSize={pageSize}
    />
  );
};

export default BrandList;
