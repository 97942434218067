import AccountCircle from '@mui/icons-material/AccountCircle';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { logout, menu } from 'src/services';
import ModalAlert from 'src/shared-components/Modal';
import { isMobile } from 'react-device-detect';
// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

interface IInitProps {
  openDrawer: boolean;
  handleDrawerOpen: () => void;
  AppBar: any;
}

const Header = (props: IInitProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { openDrawer, handleDrawerOpen, AppBar } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [routeName, setRouteName] = useState<any>();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const logOut = () => {
    logout();
    navigate('/login');
  };

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenProfileModal = () => {
    setOpen(true);
    handleMenuClose();
  };

  useEffect(() => {
    const name = menu().find((route: any) => route.pathName === location?.pathname);
    setRouteName(name);
  }, [location?.pathname]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={'icon-profile'}
    >
      <MenuItem onClick={handleOpenProfileModal}>Tài khoản</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={logOut}>Đăng xuất</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const checkMobile = () => {
    if (isMobile) {
    }
  };

  return (
    <>
      <AppBar position="fixed" open={openDrawer}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openDrawer && { display: 'none' }),
            }}
            className={'icon-toggle'}
          >
            <MenuIcon />
          </IconButton>
          {isMobile && openDrawer && (
            <IconButton onClick={handleDrawerOpen} className="view-toggle">
              <PlaylistPlayRoundedIcon className="icon-menu-right" aria-label="open drawer" />
            </IconButton>
          )}
          {!isMobile && (
            <>
              <a href="/dashboard" className="home-title">
                Trang chủ
              </a>
              <ChevronRightIcon className="icon-right-header" />{' '}
            </>
          )}
          <a className="home-title" style={{ color: '#4D4D4D' }}>
            {routeName?.text || 'Chi tiết'}
          </a>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              className={'icon-toggle'}
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <ModalAlert
        isButton={true}
        open={open}
        handleClose={() => handleCloseModal()}
        handleCancel={() => handleCloseModal()}
        handleConfirm={() => {}}
        title={'Thông tin tài khoản'}
        renderContentModal={
          <div style={{ flexDirection: 'column', alignItems: 'center' }}>
            <div className="view-input-discount" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <div>
                <span>Tên đăng nhập: </span>
                <span style={{ fontSize: '15px', color: '#666666' }}>{user?.userName}</span>
              </div>

              <div>
                <span style={{ fontWeight: '500' }}>Tên: </span>
                <span style={{ fontSize: '15px', color: '#666666' }}>{user?.name}</span>
              </div>
              <div>
                <span>Số điện thoại: </span>
                <span style={{ fontSize: '15px', color: '#666666' }}>{user?.phone}</span>
              </div>
            </div>
          </div>
        }
        type={'information'}
      />
    </>
  );
};

export default Header;
