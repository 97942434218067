import { Animation, Stack, ValueScale } from '@devexpress/dx-react-chart';
import { ArgumentAxis, BarSeries, Chart, Legend, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { Box, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import './dashboard.scss';
import { getDashboard } from 'src/services/overview.services';
import { currencyFormat } from 'src/utils/utils';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const data = [
  {
    state: '01/01/2023',
    young: 500,
    middle: 300,
    older: 900,
  },
  {
    state: '02/01/2023',
    young: 10,
    middle: 50,
    older: 1000,
  },
  {
    state: '03/01/2023',
    young: 40,
    middle: 70,
    older: 5000,
  },
  {
    state: '04/01/2023',
    young: 30,
    middle: 5000,
    older: 7000,
  },
];

const Dashboard = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const firstDay = new Date(currentYear, currentMonth - 1, 1);
  const lastDay = new Date(currentYear, currentMonth - 1, new Date(currentYear, currentMonth, 0).getDate());

  const [actualRevenue, setActualRevenue] = useState<number>();
  const [expectedRevenue, setExpectedRevenue] = useState<number>();
  const [totalOrder, setTotalOrder] = useState<number>();
  const [totalCustomer, setTotalCustomer] = useState<number>();
  const [newCustomers, setNewCustomers] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<any>({
    dateFrom: firstDay,
    dateTo: lastDay,
  });

  const defaultValues = {
    time: null,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, reset, watch } = methods;

  const watchTime: any = watch('time');

  useEffect(() => {
    const fetchDate = async () => {
      const data = await getDashboard({
        startDate: selectedDate.dateFrom.getTime(),
        endDate: selectedDate.dateTo.getTime(),
      });
      if (data && data.data !== null) {
        setActualRevenue(data.data.actualRevenue);
        setExpectedRevenue(data.data.expectedRevenue);
        setTotalOrder(data.data.totalOrder);
        setTotalCustomer(data.data.totalCustomer);
        setNewCustomers(data.data.newCustomers);
      }
    };
    fetchDate();
  }, [selectedDate]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const firstDay = new Date(currentYear, currentMonth - 1, 1);
    const lastDay = new Date(currentYear, currentMonth - 1, new Date(currentYear, currentMonth, 0).getDate());

    setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
  }, [watchTime]);
  console.log(selectedDate.dateFrom.getTime(), selectedDate.dateTo.getTime());
  return (
    <div className="page-layout">
      <Grid className={'align-items-center header_title'}>
        <Grid md={7}>
          <h2 className={'txt-title'}>Tổng quan</h2>
        </Grid>
      </Grid>
      <Divider />
      {/* TODO SEARCH */}
      <div className="search">
        <FormProvider {...methods}>
          <Grid container className={'view-filter'} spacing={2}>
            <Grid item xs={2}>
              <FormControlCustom label="Từ ngày" fullWidth>
                <div className="view-input">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={selectedDate?.dateFrom}
                      onChange={(e: any) => {
                        setSelectedDate({ ...selectedDate, dateFrom: new Date(e) });
                      }}
                      inputFormat="DD/MM/YYYY"
                      className={'date-picker'}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </FormControlCustom>
            </Grid>
            <Grid item xs={2}>
              <FormControlCustom label="Đến ngày" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props: any) => <TextField {...props} />}
                    value={selectedDate?.dateTo}
                    onChange={(e: any) => {
                      setSelectedDate({ ...selectedDate, dateTo: new Date(e - 1000) });
                    }}
                    inputFormat="DD/MM/YYYY"
                    className="input-select"
                  />
                </LocalizationProvider>
              </FormControlCustom>
            </Grid>
          </Grid>
        </FormProvider>
      </div>

      <div style={{ display: 'inline-block', padding: '12px', margin: 1 }}>
        <Card
          sx={{
            display: 'flex',
            width: 370,
            height: 110,
            bgcolor: '#fff',
            '&:hover': {
              opacity: 0.95,
            },
          }}
        >
          <Box
            sx={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              bgcolor: '#666666',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ShoppingCartIcon sx={{ color: '#fff', fontSize: '2rem' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {totalOrder}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                Đơn hàng
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </div>

      <div style={{ display: 'inline-block', padding: '12px', margin: 1 }}>
        <Card
          sx={{
            display: 'flex',
            width: 370,
            height: 110,
            bgcolor: '#fff',
            '&:hover': {
              opacity: 0.95,
            },
          }}
        >
          <Box
            sx={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              bgcolor: '#1da1f2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PeopleAltIcon sx={{ color: '#fff', fontSize: '2rem' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {totalCustomer}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                Tổng khách hàng
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </div>

      <div style={{ display: 'inline-block', padding: '12px', margin: 1 }}>
        <Card
          sx={{
            display: 'flex',
            width: 370,
            height: 110,
            bgcolor: '#fff',
            '&:hover': {
              opacity: 0.95,
            },
          }}
        >
          <Box
            sx={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              bgcolor: '#1df24d',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PersonAddAltIcon sx={{ color: '#fff', fontSize: '2rem' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {newCustomers}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                Khách mới
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </div>

      <div style={{ display: 'inline-block', padding: '12px', margin: 1 }}>
        <Card
          sx={{
            display: 'flex',
            width: 370,
            height: 110,
            bgcolor: '#fff',
            '&:hover': {
              opacity: 0.95,
            },
          }}
        >
          <Box
            sx={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              bgcolor: '#4451cd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PersonAddAltIcon sx={{ color: '#fff', fontSize: '2rem' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {currencyFormat(expectedRevenue || 0, '')}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                Doanh thu thực tế
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </div>

      <div style={{ display: 'inline-block', padding: '12px', margin: 1 }}>
        <Card
          sx={{
            display: 'flex',
            width: 370,
            height: 110,
            bgcolor: '#fff',
            '&:hover': {
              opacity: 0.95,
            },
          }}
        >
          <Box
            sx={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              bgcolor: '#4451cd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PersonAddAltIcon sx={{ color: '#fff', fontSize: '2rem' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {currencyFormat(actualRevenue || 0, '')}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                Doanh thu dự kiến
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </div>

      <div className="container-table">
        <Chart data={data}>
          <ValueScale name="older" />

          <ArgumentAxis />
          <ValueAxis scaleName="older" showGrid={false} showLine showTicks />

          <BarSeries color="blue" valueField="young" argumentField="state" name="Doanh thu dự kiến" scaleName="older" />
          <BarSeries
            color="green"
            valueField="middle"
            argumentField="state"
            name="Doanh thu thực tế"
            scaleName="older"
          />
          <Animation />
          <Stack />
          <Legend
            position="top"
            rootComponent={props => (
              <div className="custom-legend">
                <h2>
                  <span role="img" aria-label="family">
                    Biểu đồ doanh thu
                  </span>
                </h2>
                <Legend.Root {...props} style={{ flexDirection: 'row', display: 'flex' }} />
              </div>
            )}
            itemComponent={props => <Legend.Item {...props} sx={{ flexDirection: 'row' }} />}
            labelComponent={props => <Legend.Label {...props} />}
          />
        </Chart>
      </div>
    </div>
  );
};

export default Dashboard;
