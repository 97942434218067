import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import './index.scss';
import TypeProductList from './components/brandList';
import ModalAlert from 'src/shared-components/Modal';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
import { deleteType, getConfigBrand, getType, updateType } from 'src/services/app.services';

const EditBrand = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [showDrawer, setShowDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [typeProduct, setTypeProduct] = useState<any>(null);
  const [idDelete, setIdDelete] = useState<any>(null);
  const [formType, setFormType] = useState<any>(null);
  const [selectedSku, setSelectedSku] = useState<any>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [searchValue, setSearchValue] = useState<any>('');
  const [typeText, setTypeText] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterParams, setFilterParams] = useState<any>(null);
  const debouncedSearch = useDebounce(searchValue, 500);

  const { data: configBrand, refetch } = useQuery(['configBrand'], () => getConfigBrand({}));

  const mutationUpdateType = useMutation({
    mutationFn: updateType,
    onSuccess: (data: any) => {
      toast('success', 'Cập nhật thành công');
      setOpenModal(false);
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
      setOpenModal(false);
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationDeleteType = useMutation({
    mutationFn: deleteType,
    onSuccess: (data: any) => {
      toast('success', 'Xóa thành công');
      setOpenModal(false);
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
      setOpenModal(false);
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch });
  }, [debouncedSearch]);

  useEffect(() => {
    if (!showDrawer) {
      setTypeProduct('');
      setFormType('');
    }
  }, [showDrawer]);

  const handelShowDetail = (data: string) => {
    setShowDrawer(true);
    setTypeProduct(data);
    setFormType('update');
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = (data: any) => {
    setOpenModal(true);
    setIdDelete(data);
  };

  const onDeleteType = () => {
    const params = {
      code: idDelete?.code,
    };
    mutationDeleteType.mutate(params);
  };

  return (
    <div className={'page-layout'}>
      <Grid container className={'align-items-center header_title'}>
        <Grid item md={6}>
          <h2 className={'txt-title'}>Quản lý loại sản phẩm</h2>
        </Grid>
        <Grid item md={6}></Grid>
      </Grid>
      <Divider />
      <Grid container className="search"></Grid>
      <div className="container-table-type">
        <TypeProductList
          data={configBrand?.data || []}
          onShowDetail={handelShowDetail}
          handleChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          total={configBrand?.data?.length}
          page={page}
          pageSize={pageSize}
          refetch={refetch}
          handleOpenModal={handleOpenModal}
        />
      </div>

      <ModalAlert
        open={openModal}
        handleClose={() => handleCloseModal()}
        handleCancel={() => handleCloseModal()}
        handleConfirm={() => onDeleteType()}
        title={''}
        description={''}
        icon={true}
        renderContentModal={
          <div className="view-input-discount">
            <span> Xóa loại sản phẩm {idDelete?.value}</span>
          </div>
        }
      />
    </div>
  );
};

export default EditBrand;
