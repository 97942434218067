export const checkNameHeader = (name: any) => {
  switch (name) {
    case 'sku':
      return { nameHeader: 'Mã SKU' };
    case 'branch':
      return { nameHeader: 'Chi nhánh' };
    case 'images':
      return { nameHeader: 'Hình ảnh' };
    case 'variations':
      return { nameHeader: 'Đặc tính' };
    case 'quantity':
      return { nameHeader: 'SL' };
    case 'discountValue':
      return { nameHeader: 'Giảm giá' };
    case 'entryPrice':
      return { nameHeader: 'Giá nhập vào' };
    case 'price':
      return { nameHeader: 'Giá bán' };
    case 'priceReal':
      return { nameHeader: 'Giá cuối' };
    case 'warehouseDate':
      return { nameHeader: 'Ngày nhập kho' };
    case 'warehouseCheckDate':
      return { nameHeader: 'Ngày kiểm' };
    case 'note':
      return { nameHeader: 'Ghi chú' };
  }
};

export const checkStatusWarehouse = (status: any) => {
  switch (status) {
    case 'STOCK':
      return {
        status: 'Còn hàng',
        titleColor: '#17742E',
        backgroundColor: 'rgba(125, 218, 148, 0.5)',
      };
    case 'OUTSTOCK':
      return {
        status: 'Hết hàng',
        titleColor: '#903030',
        backgroundColor: '#F69696',
      };
  }
};

export const checkNameBranch = (code: string) => {
  switch (code) {
    case 'HOCHIMINH':
      return {
        branch: 'Hồ Chí Minh',
      };
    case 'HANOI':
      return {
        branch: 'Hà Nội',
      };
    case 'SPUPLIER':
      return {
        branch: 'Nhà cung cấp',
      };
    default:
      return {
        branch: ''
      }
  }
};

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
