import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import './index.scss';

interface ISelectProps {
  name: string;
  options: any[];
  limitTags?: number | undefined;
  error?: boolean | undefined;
  helperText?: string | boolean | undefined;
  placeholder?: string;
  children?: React.ReactNode;
  defaultValue?: string | number | boolean | undefined;
  multiple?: boolean;
  onChange?: (event: React.SyntheticEvent<Element, Event>, data: any) => void;
  optionLabelKey?: string;
  freeSolo?: boolean;
  style?: React.CSSProperties;
  renderOption?: any;
  onChangeValue?: (data?: any) => void;
  isFilterOption?: boolean;
  isAutoSelected?: boolean;
  disabled?: boolean;
  className?: string;
}

const AutocompleteCustom = (props: ISelectProps) => {
  const {
    name,
    options = [],
    limitTags = 1,
    error = false,
    helperText = '',
    defaultValue = undefined,
    placeholder = '',
    multiple = false,
    onChange,
    optionLabelKey = '',
    freeSolo = false,
    style,
    renderOption,
    onChangeValue,
    isFilterOption = false,
    isAutoSelected = false,
    disabled = false,
    className,
  } = props;

  const { control } = useFormContext();
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name + option.phone + option.deliveryTrucking + option.skuSub + option.sku,
  });
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onChangeDefault, value } }) => (
        <Autocomplete
          noOptionsText={'Không có dữ liệu'}
          options={options}
          value={value || ''}
          autoSelect={isAutoSelected}
          limitTags={limitTags}
          multiple={multiple}
          // disableCloseOnSelect={true}
          className={`select-custom ${className}`}
          getOptionLabel={(option: any) => {
            return option[optionLabelKey || 'name' || 'skuSub'] || option;
          }}
          filterOptions={(options, state) => {
            return filterOptions(options, state);
          }}
          onChange={(event, newValue: any) => {
            if (onChange) {
              return onChange(event, newValue);
            }
            return onChangeDefault(newValue);
          }}
          renderOption={(props: any, option: any, state: any) => renderOption(option, props, state)}
          defaultValue={defaultValue}
          freeSolo={freeSolo}
          style={style}
          disabled={disabled}
          renderInput={params => (
            <TextField
              onChange={(e: any) => {
                if (onChangeValue) {
                  return onChangeValue(e.target.value);
                }
              }}
              className={'font-size-1'}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
              value={value}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              {...params}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteCustom;
