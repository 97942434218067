import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Divider, Drawer, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';
import SelectCustom from 'src/shared-components/SelectCustom';
import AutocompleteCustom from 'src/shared-components/AutocompleteCustom';

import UploadImage from 'src/shared-components/UploadImage';
import * as yup from 'yup';

import { useEffect, useState } from 'react';
import { getPriceRealItem, getVariation } from 'src/services/app.services';
import { createProduct, getProduct, getProductList, getSearchSku } from 'src/services/stock.services';
import { uploadImage } from 'src/services/upload';
import { useToast } from 'src/shared-components/Toast';
import '../../../layout/defaultLayout.scss';
import noData from 'src/assets/no-data.png';
import errorImg from 'src/assets/img/error-img.png';
import { convertCurrency, currencyFormat, currencyMark, numberFormat } from 'src/utils/utils';
import { checkNameBranch } from 'src/functions/expand-header';
import useDebounce from 'src/shared-components/debounce';
import { addProductInOrder, getSearchProduct } from 'src/services/order.servies';

interface ICreateProps {
  setShowDrawer: (data: any | undefined) => void;
  showDrawer: boolean | undefined;
  customerId: string | undefined;
  type?: string;
  orderId?: string;
  refetchDetail?: any;
  listItem?: any;
}

const CreateProduct = (props: ICreateProps) => {
  const { setShowDrawer, showDrawer, customerId, type, orderId, refetchDetail, listItem } = props;
  const toast = useToast();
  const queryClient = useQueryClient();

  const [images, setImages] = useState<any>();
  const [urlImage, setUrlImage] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>(1);
  const [searchProd, setSearchProd] = useState<any>();

  const [filterParam, setFilterParam] = useState<any>();
  const [filterProdParam, setFilterProdParam] = useState<any>();

  const debouncedSearch = useDebounce(searchValue, 500);
  const debouncedSearchProd = useDebounce(searchProd, 500);

  const schema = yup.object().shape({
    codeSku: yup.object().typeError('Vui lòng chọn mã SKU').required('Vui lòng chọn mã SKU'),
    amount: yup.string().required('Vui lòng nhập số lượng'),
  });

  const defaultValues: any = {
    productId: '',
    productName: '',
    amount: '1',
    priceEnter: '',
    price: '',
    supplier: '',
    branch: '',
    note: '',
    variation: [],
  };

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
    criteriaMode: 'all',
  });

  const { handleSubmit, reset, formState, setValue, watch, setError, clearErrors, register } = methods;
  const { errors } = formState;
  const skuWatch = watch('codeSku');
  const branchWatch = watch('branch');
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const { data: priceItem } = useQuery(
    ['priceItem', { ...filterParam }, skuWatch?.id],
    () => getPriceRealItem(filterParam),
    {
      enabled: !!skuWatch?.id,
    },
  );

  const { data: productList, refetch } = useQuery(
    ['productList', { ...filterProdParam }],
    (params: any) => getSearchProduct(filterProdParam),
    {
      enabled: true,
      // keepPreviousData: true,
    },
  );

  const { data: searchSku } = useQuery(['searchSku'], (params: any) => getSearchSku({}));
  const { data: variation } = useQuery(['variation'], (params: any) => getVariation({}));
  // const { data: productList, refetch } = useQuery(
  //   ['productList'],
  //   (params: any) => getProductList({ page: 1, pageSize: 10 }),
  //   {
  //     enabled: true,
  //     keepPreviousData: true,
  //   },
  // );

  const variationData = watch('variation');
  const watchPriceEnter = watch('priceEnter');
  const watchAmount = watch('amount');

  const mutationStock = useMutation({
    mutationFn: createProduct,
    onSuccess: (data: any) => {
      toast('success', 'Thêm mới thành công');
      goBack();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const mutationAddProduct = useMutation({
    mutationFn: addProductInOrder,
    onSuccess: (data: any) => {
      toast('success', 'Thêm mới thành công');
      refetchDetail();

      goBack();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  useEffect(() => {
    const codeSku: any = skuWatch;
    const convertPrice = numberFormat(codeSku?.price);
    setValue('productName', codeSku?.name);
    setValue('branch', checkNameBranch(codeSku?.branch)?.branch);
    // setValue('price', currencyFormat(convertPrice, ''));
    // setValue('note', codeSku?.note);
    setValue('variation', codeSku?.productVariations);
    setImages(codeSku?.images);
    setValue('amount', '1');
  }, [skuWatch, branchWatch, watchAmount]);

  useEffect(() => {
    setValue('price', currencyFormat(priceItem?.data?.priceReal || 0, ''));
  }, [priceItem]);

  useEffect(() => {
    setSearchValue(Number(watchAmount));
    setValue('amount', watchAmount);
  }, [watchAmount]);

  useEffect(() => {
    if (skuWatch?.id) {
      let itemProd: any = [];
      listItem?.map((item: any) => {
        itemProd.push({ productId: item.productId, quantity: item.quantity });
      });

      const params = {
        id: skuWatch?.id,
        quantity: Number(debouncedSearch),
        customerId: customerId,
        listItems: itemProd,
      };
      setFilterParam(params);
    }
  }, [debouncedSearch, skuWatch]);

  useEffect(() => {
    setFilterProdParam({ ...filterParam, key: debouncedSearchProd?.data });
  }, [debouncedSearchProd]);

  const goBack = () => {
    reset();
    setShowDrawer(false);
    setImages([]);
    setUrlImage([]);
  };

  const onDelete = (idx: any) => {
    let tmpRow: any;
    const variationWatch = watch('variation');
    tmpRow = variationWatch.filter((itemId: any, index: number) => index !== idx);
    setValue('variation', JSON.parse(JSON.stringify(tmpRow)));
  };

  const onChangeSearch = (data: any) => {
    setSearchProd({
      data,
    });
  };

  const getUrlFromIMG = async (fromData: any) => {
    const data = new FormData();
    fromData.map((item: any) => data.append('images', item.file, item.name));
    const a = await uploadImage(data);
    const url = a?.data?.images.map((item: any) => item.Location);
    setUrlImage(url);
  };

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);
    funcUpload(imageList);
  };

  const funcUpload = async (image: any) => {
    function readFileAsync() {
      return new Promise((resolve, reject) => {
        const file = image;
        getUrlFromIMG(file).then((response: any) => {
          if (!response) {
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: response?.data?.id,
              url: response?.data?.url,
              name: response?.data?.name,
              type: 'image',
            });
          };
          reader.onerror = reject;
        });
      });
    }
    await readFileAsync();
  };

  const toggleDrawer = (open: any) => (event: any) => {
    reset();
    setShowDrawer(open);
  };

  const buildOptionSelect = (option: any, props: any) => {
    return (
      <div style={{ width: '500px' }} {...props}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item>
            <img
              src={option?.images?.[0]}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              width="36"
              height="36"
            />
          </Grid>
          <Grid item style={{ marginLeft: '5px' }}>
            <div className={'class-display'} style={{ width: '400px' }}>
              <span style={{ fontSize: '13px', color: '#0C59CC' }}> {option?.skuSub}</span>
              <span style={{ fontSize: '13px', wordBreak: 'break-word', lineHeight: 1 }}>- {option?.name}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '400px' }}>
              <div className={'class-display'}>
                <span style={{ fontSize: '13px', color: '#808080' }}>Giá tiền: </span>
                <span style={{ fontSize: '13px' }}>{convertCurrency(option?.price)} </span>
                <span style={{ fontSize: '14px', color: '#808080' }}>{'|   '}</span>
                <span style={{ fontSize: '13px', color: '#808080' }}>Sl: </span>
                <span style={{ fontSize: '14px' }}>{option?.quantity} </span>
                {!isEmpty(option.productVariations) && (
                  <span style={{ fontSize: '14px', color: '#808080' }}>{'|   '}</span>
                )}
                <span style={{ fontSize: '13px' }}>{option.productVariations?.[0]?.value} </span>
                {option.productVariations?.[0]?.value && option.productVariations?.[1]?.value && (
                  <span style={{ fontSize: '14px', color: '#808080' }}>,</span>
                )}
                <span style={{ fontSize: '13px' }}>{option.productVariations?.[1]?.value} </span>
                <span style={{ fontSize: '14px', color: '#808080' }}> | </span>
                <span style={{ fontSize: '13px' }}>{checkNameBranch(option?.branch)?.branch}</span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider />
      </div>
    );
  };

  const onSubmit = (value: any) => {
    if (Number(priceItem?.data?.quantity) < Number(watchAmount)) {
      toast('warning', 'Không đủ số lượng');
    } else {
      const params = {
        productId: value?.codeSku.id,
        images: images,
        branch: value.branch,
        name: value.productName,
        quantity: numberFormat(value?.amount),
        price: numberFormat(value?.price),
        note: value.note,
        sku: value?.codeSku?.skuSub,
        variations: value?.variation,
        user: user?.userName,
      };
      const paramsProd = {
        id: orderId,
        quantity: numberFormat(value?.amount),
        productId: value?.codeSku?.id,
        note: value?.note,
        user: user?.userName,
      };
      // mutationStock.mutate(params);
      type === 'detail' ? mutationAddProduct.mutate(paramsProd) : queryClient.setQueryData(['createProduct'], params);
      goBack();
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Thêm mới</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Thông tin sản phẩm</span>
            </div>
            <div className="content">
              <Grid container spacing={1.5}>
                <Grid item xs={8} className="auto-complete">
                  <FormControlCustom label={'Mã sản phẩm'} fullWidth>
                    <AutocompleteCustom
                      name={'codeSku'}
                      placeholder={'Chọn mã Sku'}
                      error={Boolean(errors?.codeSku)}
                      helperText={errors?.codeSku?.message as string}
                      options={productList?.data || []}
                      optionLabelKey={'skuSub'}
                      renderOption={buildOptionSelect}
                      onChangeValue={onChangeSearch}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Tên sản phẩm'} fullWidth>
                    <InputField
                      name={'productName'}
                      placeholder={''}
                      error={Boolean(errors.productName)}
                      helperText={errors?.productName?.message as string}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Chi nhánh'} fullWidth>
                    <InputField
                      name={'branch'}
                      helperText={errors?.branch?.message as string}
                      error={Boolean(errors.branch)}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Giá sản phẩm'} fullWidth>
                    <InputField
                      name={'price'}
                      helperText={errors?.price?.message as string}
                      error={Boolean(errors.price)}
                      placeholder={'0'}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Số lượng'} fullWidth>
                    <InputField
                      name={'amount'}
                      helperText={errors?.amount?.message as string}
                      error={Boolean(errors.amount)}
                      placeholder={'0'}
                      type="number"
                      onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField name={'note'} placeholder={'Nhập ghi chú'} className="field-note" />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Hình ảnh sản phẩm</span>
            </div>
            <div className="view-image">
              <div className={isEmpty(images) ? 'image-product' : ''}>
                <div className="image-item">
                  {images?.map((item: any, index: number) => {
                    return <img src={item} alt="" width="100" style={{ marginLeft: '5px' }} key={index} />;
                  })}
                </div>
              </div>
            </div>
            <div className="title-group">
              <span>Đặc tính sản phẩm</span>
            </div>
            <div className="content">
              {/* <CharacteristicList data={[]} />
               */}
              <TableContainer component={Paper} className={'drawer-table'} style={{ width: '100%' }}>
                <Table
                  sx={{
                    minWidth: 450,
                    '&:last-child td, &:last-child th': {
                      border: '1px solid #D7D7D7',
                    },
                    boxShadow: 'unset',
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'rgba(245, 245, 245, 0.5)' }}>
                      <TableCell width={'150'} align="center">
                        Tên
                      </TableCell>
                      <TableCell width={'200'} align="center">
                        Giá trị
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {variationData?.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            Width: 200,
                            wordBreak: 'break-word',
                          }}
                        >
                          <SelectCustom
                            name={`variation.${index}.name`}
                            placeholder={'Chọn chi nhánh'}
                            options={variation?.data as []}
                            freeSolo
                            onChange={(e, newValue) => setValue(`variation.${index}.name`, newValue?.name || newValue)}
                            disabled={true}
                            className={'disabled-field'}
                          />
                        </TableCell>
                        <TableCell align="left" sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                          <InputField
                            name={`variation.${index}.value`}
                            placeholder={'Nhập tên'}
                            disabled={true}
                            className={'disabled-field'}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {isEmpty(variationData) && (
                <div className={'btn-add-product'}>
                  <div className={'view-btn-add'}>
                    <img src={noData} className="empty-data" />
                    <span>Chưa có dữ liệu</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className={'btn-tertiary-normal'} type="submit">
                  Thêm sản phẩm
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default CreateProduct;
