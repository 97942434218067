export const client_type = {
  1: 'Khách buôn',
  2: 'CTV',
  3: 'Khách lẻ',
};

export const branchName = {
  HOCHIMINH: 'Hồ Chí Minh',
  HANOI: 'Hà Nội',
};
