import clientRequest from 'src/config/axios';

export const getDiary = async (params: any) => {
  const res = await clientRequest.get(`log`, {
    params: {
      ...params,
    },
  });
  return res;
};
