import { FormControl, FormLabel } from '@mui/material';
import './index.scss';

interface IFormControlCustom {
  isMarked?: boolean | undefined;
  label?: string | undefined;
  oldOrderId?: string | undefined;
  isDisabled?: boolean | undefined;
  className?: string | undefined;
  classNameLabel?: string | undefined;
  children: React.ReactNode;
  fullWidth?: boolean | undefined;
}

const FormControlCustom = (props: IFormControlCustom) => {
  const { isMarked = false, label, oldOrderId, isDisabled = false, className, classNameLabel, children, ...rest } = props;
  return (
    <FormControl className={className || 'form-control'} {...rest}>
      <FormLabel
        className={`${classNameLabel} ${isDisabled ? 'text-grey-2' : 'text-secondary'}  font-medium mb-10 label`}
      >
        {label}
        {isMarked ? <label className="text-red"> * </label> : ''}
        { oldOrderId ? <> <a className="text-link" target='_blank' href={`https://basso.vn/basso/customer_order/detail/${oldOrderId}`} >(Xem đơn order) </a></> : <> </> }
      </FormLabel>
      {children}
    </FormControl>
  );
};

export default FormControlCustom;
