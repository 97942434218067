import clientRequest from 'src/config/axios';

export const getVariation = async (params: any) => {
  const res = await clientRequest.get(`variation`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const getBrand = async (params: any) => {
  const res = await clientRequest.get(`brand`);
  return res;
};

export const getSupplier = async (params: any) => {
  const res = await clientRequest.get(`supplier`);
  return res;
};

export const getProductTotal = async (params: any) => {
  const res = await clientRequest.get(`product/total`);
  return res;
};

export const getCity = async (params: any) => {
  const res = await clientRequest.get(`cities`);
  return res;
};

export const getDistricts = async (params: any) => {
  const res = await clientRequest.get(`districts/${params}`);
  return res;
};

export const getBank = async (params: any) => {
  const res = await clientRequest.get(`paymnentConfig`);
  return res;
};

export const getTypeTransfer = async (params: any) => {
  const res = await clientRequest.get(`tranferType`);
  return res;
};

export const getDelivery = async (params: any) => {
  const res = await clientRequest.get('delivery');
  return res;
};

export const getType = async (params: any) => {
  const res = await clientRequest.get(`type`);
  return res;
};

export const updateType = async (params: any) => {
  const res = await clientRequest.put(`type/${params.currentName}`, {
    ...params,
  });
  return res;
};

export const deleteType = async (params: any) => {
  const res = await clientRequest.delete(`type/${params?.code}`);
  return res;
};

export const getOrderPayment = async (params: any) => {
  const res = await clientRequest.get(`orders/${params}/payment`);
  return res;
};

export const getUsers = async (params: any) => {
  const res = await clientRequest.get(`users`);
  return res;
};

export const getPriceRealItem = async (params: any) => {
  const res = await clientRequest.get(`pricerealitem`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const getLoadAllItem = async (params: any) => {
  const res = await clientRequest.get(`order/loadallitem`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const getConfigBrand = async (params: any) => {
  const res = await clientRequest.get(`config/brand`);
  return res;
};

export const editConfigBrand = async (params: any) => {
  const res = await clientRequest.put(`config/brand/${params?.id}`, {
    ...params,
  });
  return res;
};

export const deleteConfigBrand = async (params: { brandIds: string[] }) => {
  const res = await clientRequest.delete('config/brand', { data: params });

  return res;
};
