import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, Radio } from '@mui/material';
import Button from '@mui/material/Button';
import { SetStateAction, useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import './index.scss';
import { useLocation } from 'react-router-dom';

interface IUploadImageProps {
  onChange: (value: ImageListType, addUpdatedIndex?: number[] | undefined) => void;
  images: any;
  icon?: boolean;
  isLoading?: boolean;
  onDelete?: any;
  onImageRemove?: any;
  openImage?: any;
  sortAble?: boolean;
  setItems?: SetStateAction<any>;
}

const UploadImage = (props: IUploadImageProps) => {
  const {
    onChange,
    images,
    icon = false,
    isLoading,
    onDelete,
    onImageRemove,
    openImage,
    setItems = undefined,
    sortAble = false,
  } = props;
  const [selectValue, setSelectValue] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    const newImageList = [...images];
    if ((selectValue >= newImageList?.length || selectValue < 0 || newImageList?.length === 0) && setItems)
      return setItems([]);
    const elementToMove = newImageList[selectValue];    
    //if(elementToMove !== undefined && location.pathname === '/sku-manager'){
      //localStorage.setItem('image', JSON.stringify(elementToMove));
    //}
    
      
    newImageList.splice(selectValue, 1);
    newImageList.unshift(elementToMove);
    if (setItems) setItems(newImageList);
  }, [images, selectValue]);

  const handleOnChange = (e: any, index: number) => {
    e.stopPropagation();
    setSelectValue(index);
  };

  const maxNumber = 69;
  return (
    <div>
      <ImageUploading
        multiple={true}
        value={images || []}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg', 'png']}
      >
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
          return (
            <div
              className="upload__image-wrapper"
              style={{
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              {imageList?.map((image:any, index:any) => (
                <div key={index} className="image-item" onClick={e => openImage(image)}>
                  <Button
                    className="close"
                    onClick={e => {
                      e.stopPropagation();
                      onImageRemove(index);
                    }}
                  >
                    <CancelIcon />
                  </Button>
                  {sortAble && (
                    <Radio
                      sx={{
                        '& .MuiRadio-root ': { height: 'fit-content' },
                        position: 'absolute',
                        bottom: '-18px',
                        right: '-17px',
                      }}
                      checked={selectValue === index}
                      onClick={e => handleOnChange(e, index)}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'B' }}
                    />
                  )}
                  <img src={image?.data_url || image?.url || image} alt="" width="100" draggable={false} />
                </div>
              ))}

              {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress className="loading-upload" />
                </div>
              )}
              <div className={imageList?.length ? 'view-upload-length' : 'view-upload'}>
                <div
                  className="upload-image"
                  style={isDragging ? { color: 'red' } : {}}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <AddPhotoAlternateOutlinedIcon />
                </div>
                {!imageList?.length && <span className="txt-limit">** kích thước tối đa: 1MB</span>}
              </div>
            </div>
          );
        }}
      </ImageUploading>
    </div>
  );
};

export default UploadImage;
