import moment from 'moment';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Button, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { convertCurrency } from 'src/utils/utils';
import errorImg from 'src/assets/img/error-img.png';
import { checkNameBranch, checkNameHeader, checkStatusWarehouse } from 'src/functions/expand-header';
import { columnsOrder, headerChildOrder, rowChild } from 'src/utils/app-data';
import noData from 'src/assets/no-data.png';
import './orderExpand.scss';
import React from 'react';
import { checkStatusOrder, checkStatusOrderDetail } from 'src/functions/order-statuts';

function ExpandRow(props: any) {
  const {
    row,
    length,
    selectedRow,
    onEditNote,
    handleChangeNote,
    selectedChildRow,
    selectedItemChild,
    onShowDetail,
    text,
    changeTextNote,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const pricePay = () => {
    let totalNeedPaid: Number = 0;
    let totalPaid: number = 0;
    if (Number(row?.totalPaid) > Number(row?.total)) {
      totalPaid = Number(row?.total);
      totalNeedPaid = 0;
      return { totalPaid, totalNeedPaid };
    } else {
      totalPaid = Number(row?.totalPaid);
      totalNeedPaid = Number(row?.total) - Number(row?.totalPaid);
      return { totalPaid, totalNeedPaid };
    }
  };
  return (
    <>
      <TableRow
        hover
        sx={{
          '& .MuiTableBody-root:hover': {
            backgroundColor: '#76B8CD1A',
          },
          '& .MuiTableRow-selected': {
            backgroundColor: '#76b8cd1a',
          },
        }}
        onClick={() => {
          selectedRow(row);
        }}
      >
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {moment(row?.createdAt)?.format('DD/MM/YYYY')}
          </div>
        </TableCell>
        <TableCell align="center">
          <div
            style={{
              textDecorationLine: 'underline',
              fontSize: '0.8rem',
              display: 'inline-block',
              textTransform: 'none',
            }}
            onClick={() => onShowDetail(row?.id)}
          >
            <a
              href={`/order/detail/${row?.id}`}
              style={{
                color: '#1A89AC',
              }}
            >
              {row?.orderNumber}
            </a>
          </div>
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '0.8rem'}}>{row?.customer?.name}</div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="none"
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
          style={{ padding: '4px' }}
        >
          <div style={{ padding: '2px 15px' }}>
            <div
              style={{
                backgroundColor: checkStatusOrder(row?.status)?.backgroundColor,
                borderRadius: '15px',
                padding: '2px 5px',
                minWidth: '70px',
              }}
              className={'padding-status'}
            >
              <span
                style={{
                  color: 'white',
                  fontSize: '0.8rem',
                }}
              >
                {checkStatusOrder(row?.status)?.status}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell
          align="center"
          // style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {convertCurrency(row?.totalAmount)}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {convertCurrency(row?.total)}
        </TableCell>
        <TableCell
          align="center"
          // style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {convertCurrency(pricePay()?.totalPaid)}
        </TableCell>
        <TableCell
          align="center"
          // style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {convertCurrency(pricePay()?.totalNeedPaid)}
        </TableCell>
        <TableCell
          align="center"
          style={{ wordWrap: 'break-word', maxWidth: '110px' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {row?.note}
        </TableCell>
        <TableCell align="center">{row?.userCreate?.name}</TableCell>
        <TableCell align="center">{row?.userReview?.name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            width: '100%',
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Table sx={{ minWidth: 1050 }} size="small" aria-label="a dense table" padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '2rem' }}>
                      {'STT'}
                    </TableCell>
                    {headerChildOrder?.map((item: any, index: number) => {
                      return (
                        <TableCell align="center" style={{ width: `${item?.flex}px` }} key={item?.id + index}>
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.orderProducts?.map((childItem: any, index: number) => {
                    // const findSelected = selectedItemChild.find((sku: any) => sku === childItem?.id);
                    // const isSelectedItem = !isEmpty(findSelected) ? true : false;
                    return (
                      <TableRow key={index}>
                        <TableCell align="center" style={{ width: '3rem' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" style={{ padding: '4px' }}>
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={childItem?.product?.images?.[0]}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = errorImg;
                              }}
                              alt=""
                              style={{ aspectRatio: 1, width: '65px', padding: '2px' }}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div style={{ paddingTop: '4px' }}>
                            <span>{childItem?.product?.name}</span>
                          </div>
                          <div style={{ marginTop: '6px' }}>
                            <span>
                              <strong>Mã SP:</strong> {childItem?.product?.skuSub}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              flexDirection: 'column',
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            {childItem?.product?.productVariations.map((item: any, index: number) => {
                              if (!isEmpty(item.value)) {
                                return (
                                  <span key={index}>
                                    {item.name}: {item.value}
                                  </span>
                                );
                              }
                            })}
                          </div>
                        </TableCell>
                        <TableCell className="col-amount" align="center">
                          {childItem?.quantity}
                        </TableCell>
                        <TableCell align="center">{convertCurrency(childItem?.price)}</TableCell>
                        <TableCell align="center">
                          <div style={{ padding: '2px 15px' }}>
                            <div
                              style={{
                                backgroundColor: checkStatusOrderDetail(childItem?.status)?.backgroundColor,
                                borderRadius: '15px',
                                padding: '2px 5px',
                                minWidth: '70px',
                              }}
                              className={'padding-status'}
                            >
                              <span
                                style={{
                                  color: 'white',
                                  fontSize: '0.8rem',
                                }}
                              >
                                {checkStatusOrderDetail(childItem?.status)?.status}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {convertCurrency(Number(childItem?.price) * childItem?.quantity)}
                        </TableCell>
                        <TableCell align="center">{childItem?.product?.note}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface expandProps {
  rows: any;
  selectedRow: any;
  handleChangeNote?: any;
  selectedItemChild: any;
  total: number;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  page: number;
  pageSize: number;
  onShowDetail?: any;
  refetch?: any;
}

function OrderExpand(props: expandProps) {
  const [dataRow, setDataRow] = useState<any>();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    selectedRow,
    handleChangeNote,
    selectedItemChild,
    total,
    pageSize,
    page,
    onShowDetail,
  } = props;

  useEffect(() => {
    setDataRow(rows);
  }, [rows]);

  return (
    <>
      <TableContainer component={Paper} className="expand-table">
        <Table sx={{ minWidth: 1050 }} size="small" aria-label="a dense table" padding="none">
          <TableHead>
            <TableRow>
              {columnsOrder?.map((col: any, i: number) => {
                return (
                  <TableCell align={'center'} key={i} style={{ width: `${col?.flex}px` }}>
                    {col.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow?.map((row: any, index: number) => (
              <ExpandRow
                key={row?.id}
                row={row}
                length={index}
                selectedRow={selectedRow}
                // handleChangeNote={handleChangeNote}
                // selectedChildRow={selectedChildRow}
                // selectedItemChild={selectedItemChild}
                onShowDetail={onShowDetail}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEmpty(rows) && (
        <div className={'btn-add-product'}>
          <div className={'view-btn-add'}>
            <img src={noData} className="empty-data" />
            <span>Không có sản phẩm</span>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <p>Chuyển nhanh đến trang: </p>

        <input
          value={page + 1}
          min={1}
          onChange={event => {
            handleChangePage(event, Number(event.target.value) - 1);
          }}
          style={{ width: 50, marginLeft: 20, textAlign: 'center' }}
        />
        <p style={{ padding: '0px 12px' }}>Tổng số trang: {Math.ceil(total / pageSize)}</p>
      </div>

      <TablePagination
        className="shrink-0 "
        component="div"
        count={total || 0}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        rowsPerPageOptions={[10, 20, 30, 100]}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Số hàng hiển thị'}
      />
    </>
  );
}
export default React.memo(OrderExpand);
