import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoadingButton } from '@mui/lab';
import { Button, Drawer, Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';

// import "./index.scss";

const BrandCreateAndEdit = (props: any) => {
  const { setShowDrawer, showDrawer } = props;
  const queryClient = useQueryClient();

  const schema = yup.object().shape({
    code: yup.string().required('Mã không được phép bỏ trống'),
    name: yup.string().required('Tên thương hiệu không được phép bỏ trống'),
  });

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState } = methods;
  const { errors } = formState;
  // const [togglePass, setTogglePass] = useState(false);
  // const toast = useToast();

  const toggleDrawer = (open: any) => {
    setShowDrawer(open);
  };

  const onSubmit = (value: any) => {
    const param = {
      id: 4,
      stt: 10,
      code: value.code,
      name: value.name,
    };
    queryClient.setQueryData(['brand'], param);
    setShowDrawer(false);
    reset();
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={() => toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={() => setShowDrawer(false)}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Thêm mới</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Thương hiệu</span>
            </div>
            <div className="content">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControlCustom label={'Mã thương hiệu'} fullWidth>
                    <InputField
                      name={'code'}
                      placeholder={'Nhập mã'}
                      error={Boolean(errors.code)}
                      helperText={errors?.code?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Tên thương hiệu'} fullWidth>
                    <InputField
                      name={'name'}
                      placeholder={'Nhập tên thương hiệu'}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button
                  className="btn-secondary-disable"
                  onClick={() => {
                    setShowDrawer(false);
                    reset();
                  }}
                >
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <LoadingButton
                  className={!isEmpty(errors) ? 'btn-tertiary-disable' : 'btn-tertiary-normal'}
                  type="submit"
                >
                  {'Xác nhận'}
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default BrandCreateAndEdit;
