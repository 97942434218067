import clientRequest from 'src/config/axios';
import { ILoginRequest } from '../model/login.model';
import isEmpty from 'lodash';
export const login = (params: ILoginRequest) => {
  const { username, password, remember } = params;
  return clientRequest
    .post('login', {
      username,
      password,
      remember,
    })
    .then(response => {
      if (!isEmpty(response?.data?.access_token)) {
        localStorage.setItem('user', JSON.stringify(response?.data?.user));
        console.log('user when login', response?.data);
      }

      return response?.data;
    });
};

export const getProfile = async () => {
  const res = await clientRequest.get(`profile`);
  return res;
};

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
};
