import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import './index.scss';

interface ISelectProps {
  name: string;
  options: any[];
  limitTags?: number | undefined;
  error?: boolean | undefined;
  helperText?: string | boolean | undefined;
  placeholder?: string;
  children?: React.ReactNode;
  defaultValue?: string | number | boolean | Object | undefined;
  multiple?: boolean;
  onChange?: (event: React.SyntheticEvent<Element, Event>, data: any) => void;
  onSelectCity?: (idx: number, data: any) => void;
  optionLabelKey?: string;
  freeSolo?: boolean;
  style?: React.CSSProperties;
  renderOption?: any;
  valueRender?: any;
  disabled?: boolean;
  className?: string;
  isAutoSelected?: boolean;
  disableClear?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  idx?: number;
}

const SelectCustom = (props: ISelectProps) => {
  const {
    name,
    options = [],
    limitTags = 1,
    error = false,
    helperText = '',
    defaultValue = undefined,
    placeholder = '',
    multiple = false,
    onChange,
    optionLabelKey,
    freeSolo = false,
    style,
    renderOption,
    disabled = false,
    isAutoSelected = false,
    disableClear = false,
    className = '',
    isOptionEqualToValue = undefined,
    idx,
    onSelectCity,
  } = props;

  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onChangeDefault, value } }) => {
        return (
          <Autocomplete
            noOptionsText={'Không có dữ liệu'}
            options={options}
            autoSelect={isAutoSelected}
            value={value}
            isOptionEqualToValue={isOptionEqualToValue}
            // inputValue={valueRender}
            disableClearable={disableClear}
            limitTags={limitTags}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            className={`${className} select-custom`}
            getOptionLabel={(option: any) => {
              return option[optionLabelKey || 'name'] || option;
            }}
            onChange={(event, newValue: any) => {
              if (onSelectCity) {
                return onSelectCity(idx || 0, newValue);
              }
              if (onChange) {
                return onChange(event, newValue);
              }
              return onChangeDefault(newValue);
            }}
            // renderOption={(props: any, option: any) => renderOption(option)}
            defaultValue={defaultValue}
            freeSolo={freeSolo}
            style={style}
            disabled={disabled}
            renderInput={params => {
              if (params) {
                return (
                  <TextField
                    className={'font-size-1'}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                    value={value}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                    }}
                    {...params}
                  />
                );
              }
            }}
          />
        );
      }}
    />
  );
};

export default SelectCustom;
