import { GridColDef } from '@mui/x-data-grid';
import DataTable from 'src/shared-components/DataTable';

const ProductCategoryList = (props: any) => {
  const {
    data,
    selectionModel,
    handleSelectionModeChange,
    // handlePageChange,
    // isFetching,
    // pagination,
    // handleRowsPerPageChange,
    // handleShowDetail,
    // listFilterChecked,
    // userStatusesQuery,
  } = props;
  // const [selectionModel1, setSelectionModel1] = React.useState<GridSelectionModel>([]);
  const columns: GridColDef[] = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Mã nhóm sản phẩm',
      flex: 30,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'name',
      flex: 150,
      headerName: 'Tên nhóm sản phẩm',
      headerAlign: 'center',
      headerClassName: 'theme',
    },
    // {
    //   field: "action",
    //   flex: 150,
    //   headerName: "Thao tác",
    //   headerAlign: "center",
    //   headerClassName: "theme",
    //   renderCell: () => {
    //     return (
    //       <Button>Edit</Button>
    //     )
    //   },
    // }
  ];
  return (
    <DataTable
      // pagination
      // loading={isFetching}
      // page={pagination.page}
      // paginationMode="server"
      rows={data}
      checkboxSelection
      disableSelectionOnClick
      selectionModel={selectionModel}
      onSelectRow={(newSelectionModel: any) => {
        handleSelectionModeChange(newSelectionModel);
      }}
      // keepNonExistentRowsSelected
      // pageSize={pagination.pageSize}
      // selectionModel={selectionModel}
      // onPageChange={handlePageChange}
      // rowsPerPageOptions={[5, 10, 20]}
      // rowCount={data?.pagination.total}
      // onPageSizeChange={handleRowsPerPageChange}
      // onRowDoubleClick={(params) => handleShowDetail(params.id, 'DETAIL')}
      // onSelectionModelChange={(newSelectionModel:any) => handleSelectionModeChange(newSelectionModel)}
      columns={columns}
    />
  );
};

export default ProductCategoryList;
