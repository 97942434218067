import { yupResolver } from '@hookform/resolvers/yup';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Drawer, Grid, InputAdornment } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';
import SelectCustom from 'src/shared-components/SelectCustom';
import UploadImage from 'src/shared-components/UploadImage';
import * as yup from 'yup';

import { useEffect, useState } from 'react';
import { IStock } from 'src/model/sku.modal';
import { getVariation } from 'src/services/app.services';
import { createProduct, getProduct, getSearchSku } from 'src/services/stock.services';
import { uploadImage } from 'src/services/upload';
import { useToast } from 'src/shared-components/Toast';
import { branchCreate } from 'src/utils/app-data';
import '../../../layout/defaultLayout.scss';

interface ICreateStockProps {
  setShowDrawer: (data: any | undefined) => void;
  showDrawer: boolean | undefined;
}

const CreateStock = (props: ICreateStockProps) => {
  const { setShowDrawer, showDrawer } = props;
  const toast = useToast();

  const schema = yup.object().shape({
    codeSku: yup.object().typeError('Sku không được phép bỏ trống').required('Sku không được phép bỏ trống'),

    amount: yup.number().typeError('Vui lòng điền đúng định dạng').required('Vui lòng nhập số lượng'),
    priceEnter: yup.number().typeError('Vui lòng điền đúng định dạng').required('Vui lòng nhập giá'),
    price: yup.number().typeError('Vui lòng điền đúng định dạng').required('Vui lòng nhập giá bán'),
  });

  const defaultValues: any = {
    codeSku: '',
    productName: '',
    amount: '',
    priceEnter: '',
    price: '',
    supplier: '',
    branch: '',
    note: '',
    variation: [],
  };

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch } = methods;
  const { errors } = formState;
  const skuWatch = watch('codeSku');
  const branchWatch = watch('branch');
  const [images, setImages] = useState<any>();
  const [urlImage, setUrlImage] = useState<any>();

  const { data: searchSku } = useQuery(['searchSku'], (params: any) => getSearchSku({}));
  const { data: variation } = useQuery(['variation'], (params: any) => getVariation({}));
  const { refetch } = useQuery(['product'], (params: any) => getProduct({ page: 1, pageSize: 10 }));

  const variationData = watch('variation');
  const watchPriceEnter = watch('priceEnter');

  const mutationStock = useMutation({
    mutationFn: createProduct,
    onSuccess: (data: any) => {
      toast('success', 'Thêm mới thành công');
      refetch();
      goBack();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  useEffect(() => {
    const codeSku: any = skuWatch;
    setValue('productName', codeSku?.name);
    checkBranch();
  }, [skuWatch, branchWatch]);

  const goBack = () => {
    reset();
    setShowDrawer(false);
    setImages([]);
    setUrlImage([]);
  };

  const onDelete = (idx: any) => {
    let tmpRow: any;
    const variationWatch = watch('variation');
    tmpRow = variationWatch.filter((itemId: any, index: number) => index !== idx);
    setValue('variation', JSON.parse(JSON.stringify(tmpRow)));
  };

  const onAddForm = () => {
    const tmpForm = [
      ...watch('variation'),
      {
        value: '',
      },
    ];

    setValue('variation', JSON.parse(JSON.stringify(tmpForm)));
  };

  const checkBranch = () => {
    if (branchWatch?.codeName === 'SPUPLIER') {
      return false;
    } else {
      return true;
    }
  };

  const getUrlFromIMG = async (fromData: any) => {
    const data = new FormData();
    fromData.map((item: any) => data.append('images', item.file, item.name));
    const a = await uploadImage(data);
    const url = a?.data?.images.map((item: any) => item.Location);
    setUrlImage(url);
  };

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);
    funcUpload(imageList);
  };

  const funcUpload = async (image: any) => {
    function readFileAsync() {
      return new Promise((resolve, reject) => {
        const file = image;
        getUrlFromIMG(file).then((response: any) => {
          if (!response) {
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: response?.data?.id,
              url: response?.data?.url,
              name: response?.data?.name,
              type: 'image',
            });
          };
          reader.onerror = reject;
        });
      });
    }
    await readFileAsync();
  };

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
  };

  const onSubmit = (value: any) => {
    const params: IStock = {
      skuProductId: value?.codeSku.id,
      images: urlImage,
      branch: value.branch.codeName,
      name: value.productName,
      quantity: value.amount,
      entryPrice: Number(value.priceEnter),
      price: value.price,
      note: value.note,
      weight: 0,
      supplier: value?.supplier,
      variations: value?.variation,
    };

    mutationStock.mutate(params);
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Thêm mới</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Nhập stock</span>
            </div>
            <div className="content">
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <FormControlCustom label={'Mã SKU'} fullWidth>
                    <SelectCustom
                      name={'codeSku'}
                      placeholder={'Chọn mã Sku'}
                      error={Boolean(errors?.codeSku)}
                      helperText={errors?.codeSku?.message as string}
                      options={searchSku?.data || []}
                      optionLabelKey={'sku'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={8}>
                  <FormControlCustom label={'Tên sản phẩm'} fullWidth>
                    <InputField
                      name={'productName'}
                      placeholder={'Nhập tên sản phẩm'}
                      error={Boolean(errors.productName)}
                      helperText={errors?.productName?.message as string}
                      disabled={true}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Số lượng'} fullWidth>
                    <InputField
                      name={'amount'}
                      helperText={errors?.amount?.message as string}
                      error={Boolean(errors.amount)}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Giá nhập'} fullWidth>
                    <InputField
                      name={'priceEnter'}
                      helperText={errors?.priceEnter?.message as string}
                      error={Boolean(errors.priceEnter)}
                      placeholder={'0'}
                      type="number"
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Giá bán'} fullWidth>
                    <InputField
                      name={'price'}
                      helperText={errors?.price?.message as string}
                      error={Boolean(errors.price)}
                      placeholder={'0'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Chi nhánh'} fullWidth>
                    <SelectCustom
                      name={'branch'}
                      placeholder={'Chọn chi nhánh'}
                      error={Boolean(errors.branch)}
                      helperText={errors?.branch?.message as string}
                      options={branchCreate}
                      freeSolo
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={8}>
                  <FormControlCustom label={'Nhà cung cấp'} fullWidth>
                    <InputField
                      name={'supplier'}
                      placeholder={'Nhập nhà cung cấp'}
                      helperText={errors?.supplier?.message as string}
                      error={Boolean(errors.supplier)}
                      disabled={checkBranch()}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField name={'note'} placeholder={'Nhập ghi chú'} />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Hình ảnh sản phẩm</span>
            </div>

            <div className="view-image">
              <div className={isEmpty(images) ? 'image-product' : ''}>
                <UploadImage onChange={onChange} images={images} />
              </div>
            </div>

            <div className="title-group">
              <span>Đặc tính sản phẩm</span>
            </div>
            <div className="content">
              {/* <CharacteristicList data={[]} />
               */}
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    minWidth: 450,
                    '&:last-child td, &:last-child th': {
                      border: '1px solid #D7D7D7',
                    },
                    boxShadow: 'unset',
                    backgroundColor: 'unset',
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'rgba(245, 245, 245, 0.5)' }}>
                      <TableCell width={'150'} align="center">
                        Tên
                      </TableCell>
                      <TableCell width={'200'} align="center">
                        Giá trị
                      </TableCell>
                      <TableCell width={'80'} align="center">
                        Thao tác
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {variationData?.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            Width: 200,
                            wordBreak: 'break-word',
                          }}
                        >
                          <SelectCustom
                            name={`variation.${index}.name`}
                            placeholder={'Chọn chi nhánh'}
                            options={variation?.data as []}
                            freeSolo
                            onChange={(e, newValue) => setValue(`variation.${index}.name`, newValue?.name || newValue)}
                          />
                        </TableCell>
                        <TableCell align="left" sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                          <InputField name={`variation.${index}.value`} placeholder={'Nhập tên'} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ maxWidth: 50, wordBreak: 'break-word' }}
                          onClick={() => onDelete(index)}
                        >
                          <DeleteForeverOutlinedIcon style={{ color: '#F05050' }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button className={'btn-add'}>
                <div className={'view-btn-add'} onClick={onAddForm}>
                  <AddOutlinedIcon />
                  <span> Thêm thuộc tính</span>
                </div>
              </Button>
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className={!isEmpty(errors) ? 'btn-primary-disable' : 'btn-tertiary-normal'} type="submit">
                  {'Xác nhận'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default CreateStock;
