import { Button } from '@mui/material';

import './index.scss';
import errorImg from 'src/assets/img/error-img.png';
import DataTableV2 from 'src/shared-components/DataTableV2';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import trash from 'src/assets/trash.jpg';
import edit from 'src/assets/edit.jpg';

const TypeProductList = (props: any) => {
  const {
    data,
    selectionModel,
    handleSelectionModeChange,
    handleShowDetail,
    handleClick,
    onChangeRowsPerPage,
    handleChangePage,
    total,
    page,
    pageSize,
    onShowDetail,
    handleOpenModal,
  } = props;

  const columns: any = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Mã Loại sản phẩm',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Tên loại sản phẩm',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'icon',
      headerName: 'Ảnh Basso',
      flex: 70,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={params.row.icon}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              style={{ aspectRatio: 1, width: '60px', backgroundSize: 'cover' }}
            />
          </div>
        );
      },
    },
    {
      field: 'iconShipUs',
      headerName: 'Ảnh ShipUs',
      flex: 70,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={params.row.iconShipUs}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              style={{ aspectRatio: 1, width: '60px', backgroundSize: 'cover' }}
            />
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      flex: 10,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div style={{}}>
            <Button
              onClick={() => onShowDetail(params?.row)}
              style={{ backgroundColor: 'transparent', marginRight: -20 }}
            >
              <img src={edit} style={{ aspectRatio: 1, width: '23px', backgroundSize: 'cover' }} />
            </Button>
            <Button onClick={() => handleOpenModal(params?.row)} style={{ backgroundColor: 'transparent' }}>
              <img src={trash} style={{ aspectRatio: 1, width: '25px', backgroundSize: 'cover' }} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <DataTableV2
      rows={data}
      columns={columns}
      {...props}
      handleChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleShowDetail={() => {}}
      total={total}
      page={page}
      pageSize={pageSize}
    />
  );
};

export default TypeProductList;
