import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { Button, Drawer, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AutocompleteCustom from 'src/shared-components/AutocompleteCustom';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';

import { getProduct } from 'src/services/stock.services';
import { checkStatusShipping } from 'src/functions/order-statuts';
import { IOrderDetail } from 'src/model/order.modal';
import { getCity, getDelivery, getDistricts } from 'src/services/app.services';
import { createItemRefund, createOrderCustomer, createOrderTracking, getTracking } from 'src/services/order.servies';
import useDebounce from 'src/shared-components/debounce';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useToast } from 'src/shared-components/Toast';
import { branchData, columnProductTracking, columnRefundOrder } from 'src/utils/app-data';
import { currencyFormat, currencyMark, numberFormat } from 'src/utils/utils';
import '../../../layout/defaultLayout.scss';
import '../order.scss';
import RefundTable from './refundTable';

interface ICreateProps {
  setShowDrawer: (data: any | undefined) => void;
  showDrawer: boolean | undefined;
  productList: any;
  data: any;
  address?: any;
  refetch: any;
  refetchProd?: any;
  refundFetch?: any;
}
const phoneRegExp = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

const RefundOrder = (props: ICreateProps) => {
  const { setShowDrawer, showDrawer, data, productList, refetch, refetchProd, refundFetch } = props;
  const toast = useToast();
  const dataOrder: IOrderDetail = data;

  useEffect(() => { }, [JSON.stringify(dataOrder)]);

  const [selected, setSelected] = useState<any>([]);
  const [dataProduct, setDataProduct] = useState<any>(productList);
  const [filterParams, setFilterParams] = useState<any>({ status: 'IN_PROCESS' });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<any>();
  const [trackingList, setTrackingList] = useState<any>([]);
  const [isBranchSame, setIsBranchSame] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<any>();
  const [isChange, setIsChange] = useState<any>();
  const debouncedSearch = useDebounce(searchValue, 500);

  const schema = yup.object().shape({
    reasonRefund: yup.string().typeError('Lý do không được phép bỏ trống').required('Lý do không được phép bỏ trống'),
    refundFee: yup.string().required('Vui lòng nhập phí khách hàng trả'),
    deliveryTrucking: yup.string().required('Vui lòng nhập mã vận đơn'),
    deliveryId: yup.object().typeError('Vui lòng chọn đơn vị').required('Vui lòng chọn đơn vị'),
    branch: yup.object().typeError('Vui lòng chọn chi nhánh').required('Vui lòng chọn chi nhánh'),
  });

  const defaultValues: any = useMemo(
    () => ({
      reasonRefund: '',
      note: '',
      deliveryTrucking: '',
      refundFee: '',
      deliveryId: null,
      branch: '',
    }),
    [dataOrder],
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch, getValues } = methods;
  const { errors } = formState;
  const watchRefundFee = watch('refundFee');
  const watchBranch = watch('branch');

  const [cityID, setCityId] = useState<any>(dataOrder?.customerAddress?.cities?.id);

  const { data: districtData } = useQuery(['district', cityID], () => getDistricts(cityID), { enabled: !!cityID });
  const { data: deliveryData } = useQuery(['delivery'], () => getDelivery({}));
  const { data: truckingData } = useQuery(['trucking', { ...filterParams }], () => getTracking({ ...filterParams }));

  const mutationStock = useMutation({
    mutationFn: createOrderCustomer,
    onSuccess: (data: any) => {
      toast('success', 'Thêm khách hàng thành công');
      refetch();
      refetchProd();
      goBack();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const mutationRefundOrder = useMutation({
    mutationFn: createItemRefund,
    onSuccess: () => {
      toast('success', 'Hoàn đơn thành công');
      refetch();
      goBack();
      setSelected([]);
      refetchProd();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message || 'Thất bại');
      }
    },
  });

  useEffect(() => {
    setValue('refundFee', currencyMark(watchRefundFee));
  }, [watchRefundFee]);

  useEffect(() => {
    let arr: any = [];
    if (selected.length > 1) {
      for (var i = 0; i < watchBranch?.length - 1; i++) {
        var currentValue = watchBranch[i];
        var nextValue = watchBranch[i + 1];
        if (currentValue?.codeName === nextValue?.codeName) {
          setIsBranchSame(true);
        } else {
          setIsBranchSame(false);
        }
      }
    } else {
      setIsBranchSame(true);
    }
  }, [JSON.stringify(watchBranch), selected, dataOrder]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [data]);

  useEffect(() => {
    let totalPrice = 0;
    if (!isEmpty(selected)) {
      selected.map((item: any, i: number) => {
        totalPrice += selected[i].price * selected[i].quantity;
      });
      setValue('refundFee', currencyFormat(totalPrice, ''));
    } else {
      setValue('refundFee', '');
    }
  }, [selected]);

  useEffect(() => {
    if (!isEmpty(truckingData?.data?.data)) {
      let tracking = [...truckingData?.data?.data];
      setTrackingList(tracking);
    } else {
      let tracking: any = [];
      setTrackingList(tracking);
    }
  }, [truckingData]);

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      deliveryTrucking: debouncedSearch?.data,
      status: 'IN_PROCESS',
    });
  }, [debouncedSearch]);

  useEffect(() => {
    setSelected([]);
  }, [isChange]);

  const handleClick = (name: any) => {
    const items: any = [];
    const checkExits = selected.find((item: any) => item.orderDetailId === name.orderDetailId);
    if (!isEmpty(checkExits)) {
      const remove = selected.filter((item: any) => item.orderDetailId !== name.orderDetailId);
      // items.push(remove);
      setSelected(remove);
    } else {
      let a = [...selected];
      a.push(name);
      setSelected(a);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let items: any = [];
      const newSelected = productList?.map((n: any) => {
        items.push({ orderDetailId: n?.id, quantity: Number(n?.quantity), price: n.price });
      });
      setSelected(items);
      return;
    }
    setSelected([]);
  };

  const goBack = () => {
    reset();
    setShowDrawer(false);
    refundFetch();
  };

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
  };

  const onChangeQuantity = (event: any) => {
    setQuantity(event.target.value);
    setIsChange(!isChange);
  };

  const onChangeCustomer = (data: any) => {
    setSearchValue({
      data,
    });
  };

  const onSubmit = (values: any) => {
    if (!isEmpty(selected)) {
      const params = {
        reason: values.reasonRefund,
        deliveryTrucking: values?.deliveryTrucking,
        refundFee: numberFormat(values?.refundFee),
        deliveryId: values?.deliveryId.id,
        branch: watchBranch?.codeName,
        items: selected,
        id: dataOrder.id,
        note: values?.note || ' ',
      };
      mutationRefundOrder.mutate(params);
    } else {
      toast('warning', 'Vui lòng chọn sản phẩm');
    }
  };

  const a = useCallback(
    (option: any, props: any, state: any) => {
      const checkBackground = () => {
        if (state?.index % 2 !== 0) {
          return { background: '#f5f7fa' };
        } else {
          return { background: 'white' };
        }
      };
      return (
        <>
          <div style={option.id === 0 ? {} : { backgroundColor: checkBackground().background }}>
            <div style={{ paddingBottom: '-2px', paddingTop: '-2px', display: 'flex' }} {...props}>
              <div style={{ width: '60%', justifyContent: 'center', display: 'flex' }}>
                <span> {option?.deliveryTrucking}</span>
              </div>
              <div style={{ width: '40%' }}>
                <span>{checkStatusShipping(option?.status).status}</span>
              </div>
            </div>
          </div>
        </>
      );
    },
    [undefined],
  );

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Hoàn hàng vận chuyển</span>
            </div>
          </div>
          <div style={{ marginBottom: '15px', marginLeft: '20px', marginRight: '20px' }} className={'content'}>
            <RefundTable
              rows={productList || []}
              columns={columnRefundOrder}
              deleteProduct={() => { }}
              checkboxSelection={true}
              selected={selected}
              handleSelectAllClick={handleSelectAllClick}
              handleClick={handleClick}
              changeText={onChangeQuantity}
              text={quantity}
              isChangeQuantity={isChange}
              {...props}
            />
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Thông tin hoàn hàng</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Lý do hoàn hàng'} fullWidth>
                    <InputField
                      name={'reasonRefund'}
                      placeholder={'Lý do hoàn'}
                      error={Boolean(errors.reasonRefund)}
                      helperText={errors?.reasonRefund?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Số tiền hoàn hàng'} fullWidth>
                    <InputField
                      name={'refundFee'}
                      helperText={errors?.refundFee?.message as string}
                      error={Boolean(errors?.refundFee)}
                      placeholder={'0'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Chi nhánh'} fullWidth>
                    <SelectCustom
                      name={'branch'}
                      placeholder={'Chọn chi nhánh'}
                      error={Boolean(errors?.branch)}
                      helperText={errors?.branch?.message as string}
                      options={branchData}
                      optionLabelKey={'name'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Mã vận đơn'} fullWidth>
                    <InputField
                      name={'deliveryTrucking'}
                      helperText={errors?.deliveryTrucking?.message as string}
                      error={Boolean(errors?.deliveryTrucking)}
                      placeholder={'Nhập mã vận đơn'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Đơn vị'} fullWidth>
                    <SelectCustom
                      options={deliveryData?.data}
                      placeholder={'Chọn đơn vị'}
                      name={'deliveryId'}
                      error={Boolean(errors?.deliveryId)}
                      helperText={errors?.deliveryId?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField
                      name={'note'}
                      helperText={errors?.note?.message as string}
                      error={Boolean(errors.note)}
                      placeholder={'Nhập ghi chú'}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Hủy
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className={'btn-tertiary-normal'} type="submit">
                  Xác nhận
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default RefundOrder;
