import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SelectCustom from 'src/shared-components/SelectCustom';
import { branchData } from 'src/utils/app-data';
import './index.scss';

function MuiTableRow(props: any) {
  const { row, index, handleSelectCity } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => setOpen(!open)}>
        {row?.product?.name}
      </TableCell>
      <TableCell style={{ wordBreak: 'break-word' }} onClick={() => setOpen(!open)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {row?.product?.productVariations.map((varia: any, index: number) => {
            if (!isEmpty(varia.value)) {
              return (
                <span key={index}>
                  {varia.name}: {varia.value}
                </span>
              );
            }
          })}
        </div>
      </TableCell>
      <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => setOpen(!open)}>
        <SelectCustom
          options={branchData}
          optionLabelKey={'name'}
          name={`branch.${index}`}
          disableClear={true}
          defaultValue={branchData.find(e => e.codeName === row.product.branch)?.name}
          onSelectCity={handleSelectCity}
          idx={index}
        />
      </TableCell>
      <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => setOpen(!open)}>
        {row?.quantity}
      </TableCell>
    </>
  );
}

interface expandProps {
  columns: any;
  rows: any;
  deleteProduct?: any;
  checkboxSelection?: boolean;
  onRowClick?: (event: React.MouseEvent<unknown>, name: string) => void;
  handleClick?: any;
  loadOnServerSide?: boolean;
  onSelectAllClick?: () => void;
  handleSelectAllClick?: any;
  handleSelectCity: (idx: number, value: any) => void;
}

export default function ProductTable(props: expandProps) {
  const { columns, rows, checkboxSelection, handleClick, handleSelectAllClick, handleSelectCity } = props;

  return (
    <>
      <TableContainer component={Paper} className="expand-table-order tracking-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell align={'center'} style={{ width: '2rem' }}>
                  <Checkbox
                    color="primary"
                    checked={(rows.arr as []).length === rows.checked}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
              )}
              {columns.map((col: any, i: number) => {
                return (
                  <TableCell align={'center'} key={col.field} style={{ width: col.width }}>
                    {col?.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.arr.map((row: any, index: number) => {
              let isChecked = row.checked;
              return (
                <>
                  <TableRow role="checkbox" aria-checked={isChecked} tabIndex={-1} selected={isChecked}>
                    {checkboxSelection && (
                      <TableCell
                        style={{ width: '2rem', padding: '0px' }}
                        align={'center'}
                        padding={'none'}
                        className={'checkbox'}
                      >
                        <Checkbox
                          color="primary"
                          checked={isChecked}
                          inputProps={{
                            'aria-labelledby': 'check-box-' + index,
                          }}
                          onChange={e => handleClick(index, e.target.checked)}
                        />
                      </TableCell>
                    )}
                    <MuiTableRow row={row} index={index} handleSelectCity={handleSelectCity} />
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
