import _ from 'lodash';
import moment from 'moment';
export const convertCurrency = (num: any) => `${num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}đ`;
export const convertCurrencyNo = (num: any) => `${num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export function numberFormat(string: any) {
  return _.isEmpty(string) ? 0 : Number(string?.replace(/\,/g, ''));
}

export function currencyFormat(value: number, currencyUnit: any) {
  if (value !== undefined) {
    return value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + `${currencyUnit}`;
  } else {
    return `0 ${currencyUnit}`;
  }
}

export const currencyMark = (num: string) => {
  let value = num;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{3})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
  num = value;
  return num;
};

export const formatDate = (date: any, isDateTime: boolean, isSecond?: boolean) => {
  const formatDateTime = `DD/MM/YYYY HH:mm${isSecond ? ':ss' : ''}`;
  return moment(date).format(isDateTime ? formatDateTime : 'DD/MM/YYYY');
};

export function stringCompare(string: string) {
  string = string?.toLowerCase();
  string = string?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  string = string?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  string = string?.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  string = string?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  string = string?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  string = string?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  string = string?.replace(/đ/g, 'd');
  string = string?.replace(/ /g, '');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  string = string?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  string = string?.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return string;
}
