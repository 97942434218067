import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import NotFound from './admin/layout/404NotFound';
import DefaultLayout from './admin/layout/defaultLayout';

import Login from './admin/authentication/login';
import Attribute from './admin/pages/attribute/attribute';
import Brand from './admin/pages/brand/brand';
import Dashboard from './admin/pages/dashboard/dashboard';
import Order from './admin/pages/order/order';
import ProductCategory from './admin/pages/productCategory/ProductCategory';
import SKU from './admin/pages/sku/sku';
import StockManager from './admin/pages/stock/stockManager';
import OrderList from './admin/pages/orderList/orderList';
import { parseJwt } from './functions/expand-header';
import { logout } from './services';
import AuthVerify from 'src/shared-components/auth-verify';
import OrderDetail from './admin/pages/order/orderDetail';
import CopyOrder from './admin/pages/order/copyOrder';
import TypeProduct from './admin/pages/typeProduct/typeProduct';
import EditBrand from './admin/pages/editBrand/editBrand';
import Diary from './admin/pages/diary/diary';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      console.log(`Something went wrong: ${error.message}`);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  let navigate = useNavigate();
  const access_token = localStorage.getItem('access_token');

  const parseToken = parseJwt(access_token || '');

  const currentDate = new Date();
  const dateExp = new Date(parseToken?.exp * 1000);

  // useEffect(() => {
  //   if (!isEmpty(access_token) && currentDate <= dateExp) {
  //     navigate('/dashboard');
  //   } else {
  //     navigate('/login');
  //     logout();
  //   }
  // }, []);

  const logOut = () => {
    logout();
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/stock-manager" element={<StockManager />}></Route>
          <Route path="/product-category" element={<ProductCategory />}></Route>
          <Route path="/brand" element={<Brand />}></Route>
          <Route path="/sku-manager" element={<SKU />}></Route>
          <Route path="/create-order" element={<Order />}></Route>
          <Route path="/diary" element={<Diary />}></Route>
          <Route path="/order">
            <Route path="/order" element={<OrderList />} />
            <Route path="/order/detail/:id" element={<OrderDetail />} />
            <Route path="/order/copyOrder" element={<CopyOrder />} />
          </Route>
          <Route path="/setting" element={<TypeProduct />}></Route>
          <Route path="/edit-brand" element={<EditBrand />}></Route>

          <Route path="/attribute" element={<Attribute />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
      <AuthVerify logOut={logOut} />
    </QueryClientProvider>
  );
};

export default App;
