import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clientRequest from 'src/config/axios';
import { withRouter } from './with-router';
import { isEmpty } from 'lodash';

export const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props: any) => {
  let location = props.router.location;
  let navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const access_token = localStorage.getItem('access_token');
  const parseToken = parseJwt(access_token || '');

  const currentDate = new Date();
  const dateExp = new Date(parseToken?.exp * 1000);
  useEffect(() => {
    // async function callRefreshToken() {
    //   const res = await clientRequest.post(`auth/admin/refresh`, null, {
    //     headers: { callRefreshToken: true },
    //   });
    //   localStorage.setItem('access_token', res.data.data.access_token);
    // }
    if (user) {
      if (currentDate >= dateExp) {
        props.logOut();
        navigate('/login');
      }
    }
  }, [location, user]);

  return <></>;
};

export default withRouter(AuthVerify);
