import { Button, Divider, Grid } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';

import React from 'react';
import SearchInput from 'src/shared-components/SearchInput/Index';
import ProductCategoryList from './components/productCategoryList';
import ProductCreateAndEdit from './components/productCreateAndEdit';
import './productCategory.scss';

const ProductCategory = () => {
  const [showDrawer, setShowDrawer] = useState<any>({
    isShowForm: false,
    type: '',
  });
  const [selectionModel1, setSelectionModel1] = React.useState<GridSelectionModel>([]);

  const handleSelectionModeChange = (newSelection: any) => {
    setSelectionModel1(newSelection);
  };

  const dataList = [
    {
      id: 4,
      stt: 1,
      code: 'Machine',
      name: 'Đồ điện tử',
    },
    {
      id: 3,
      stt: 2,
      code: 'Sailor',
      name: 'May mặc',
    },
  ];
  return (
    <div className={'page-layout'}>
      <Grid className={'align-items-center header_title'}>
        <Grid md={7}>
          <h2 className={'txt-title'}>NHÓM SẢN PHẨM</h2>
        </Grid>
        <div className="item-btn-right">
          <Button onClick={() => setShowDrawer({ isShowForm: true, type: 'CREATE' })} className={'btn-create'}>
            <span className={'txt'}>Thêm mới</span>
          </Button>
          <Button onClick={() => setShowDrawer({ isShowForm: true, type: 'UPDATE' })} className={'btn-create'}>
            <span className={'txt'}>Chỉnh sửa</span>
          </Button>
        </div>
      </Grid>
      <Divider />
      {/* TODO SEARCH */}
      <div className="search" style={{ width: '35%' }}>
        <SearchInput className="w-[86%]" placeholder={'Nhập thông tin tìm kiếm'} />
      </div>

      <div className="container-table">
        <ProductCategoryList
          data={dataList}
          selectionModel={selectionModel1}
          handleSelectionModeChange={handleSelectionModeChange}
        />
      </div>
      <ProductCreateAndEdit
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer.isShowForm}
        formType={showDrawer?.type}
      />
    </div>
  );
};

export default ProductCategory;
