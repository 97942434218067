import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { InputAdornment, OutlinedInput, SxProps, Theme } from '@mui/material';
import './index.scss';

interface ISearchInputProps {
  placeholder?: string | undefined;
  value?: string | number | boolean | undefined;
  onChange?: (data: any | undefined) => void;
  handleSearch?: any;
  className?: string | undefined;
  sx?: SxProps<Theme> | undefined;
  setSearchValue?: any;
}

const SearchInput = (props: ISearchInputProps) => {
  const {
    placeholder = undefined,
    value = undefined,
    className = undefined,
    sx = undefined,
    // onChange = undefined,
    handleSearch = undefined,
    setSearchValue = '',
  } = props;
  return (
    <>
      <OutlinedInput
        sx={sx}
        type="text"
        className={`${className} search-input`}
        // variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={ev => {
          setSearchValue(ev.target.value);
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch(e);
          }
        }}
        color="primary"
        endAdornment={
          <InputAdornment
            sx={{
              cursor: 'pointer',
            }}
            position="start"
            onClick={() => {
              if (handleSearch) {
                handleSearch();
              }
            }}
          >
            <SearchOutlinedIcon />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'Search',
        }}
        size="small"
      />
    </>
  );
};

export default SearchInput;
