import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Drawer, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import { isEmpty } from 'lodash';

import '../../../layout/defaultLayout.scss';
import clientRequest from 'src/config/axios';
import { CreateSkuRq } from 'src/model/sku.modal';
import { uploadImage } from 'src/services/upload';
import { useToast } from 'src/shared-components/Toast';
import InputField from 'src/shared-components/InputField';
import UploadImage from 'src/shared-components/UploadImage';
import { convertCurrency, currencyFormat, currencyMark, numberFormat } from 'src/utils/utils';
import SelectCustom from 'src/shared-components/SelectCustom';
import { getBrand, getType } from 'src/services/app.services';
import FormControlCustom from 'src/shared-components/FormControlCustom';

let custom_config = {
  toolbar: [
    'heading',
    '|',
    'alignment',
    'indent',
    'outdent',
    '|',
    'fontBackgroundColor',
    'fontColor',
    'fontFamily',
    'fontSize',
    '|',
    'bold',
    'underline',
    'italic',
    'numberedList',
    'bulletedList',
    'link',
    'removeHighlight',
    '|',
    'mediaEmbed',
    'insertTable',
    'tableColumn',
    'tableRow',
    'mergeTableCells',
    '|',
    'undo',
    'redo',
  ],
};

const CreateStock = (props: any) => {
  const toast = useToast();
  const { setShowDrawer, showDrawer, dataSku, type, refetch } = props;

  const [images, setImages] = useState<any>();
  const { data: dataType } = useQuery(['type'], () => getType({}));
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imageSelected, setSelectedImage] = useState<any>();
  const { data: dataBrand } = useQuery(['brand'], () => getBrand({}));

  const defaultValues = useMemo(
    () => ({
      sku: dataSku?.sku || '',
      name: dataSku?.name || '',
      brandId: dataSku?.brandId || '',
      brand: dataSku?.brand || '',
      type: dataSku?.type || null,
      cTVPrice: dataSku?.productWholesales?.[0]?.price
        ? dataSku?.productWholesales.find((item: any) => item.productSaleCode === 'CTV')?.price ?? ''
        : '',
      cTVQuantity: dataSku?.productWholesales?.[0]?.price
        ? dataSku?.productWholesales.find((item: any) => item.productSaleCode === 'CTV')?.quantity ?? ''
        : '',
      si_1Price: dataSku?.productWholesales?.[0]?.price
        ? dataSku?.productWholesales.find((item: any) => item.productSaleCode === 'SI_1')?.price ?? ''
        : '',
      si_1Quantity: dataSku?.productWholesales?.[0]?.price
        ? dataSku?.productWholesales.find((item: any) => item.productSaleCode === 'SI_1')?.quantity ?? ''
        : '',
      si_2Price: dataSku?.productWholesales?.[0]?.price
        ? dataSku?.productWholesales.find((item: any) => item.productSaleCode === 'SI_2')?.price ?? ''
        : '',
      si_2Quantity: dataSku?.productWholesales?.[0]?.price
        ? dataSku?.productWholesales.find((item: any) => item.productSaleCode === 'SI_2')?.quantity ?? ''
        : '',
      note: dataSku?.note || '',
      images: '' || null,
      desctions: dataSku?.desctions || '',
    }),
    [dataSku],
  );

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const schema = yup.object().shape({
    sku: yup
      .string()
      .matches(/^\S*$/, 'Mã SKU không chưa khoảng trắng')
      .matches(/^[A-Za-z0-9]*$/, 'Không chứa kí tự đặc biệt')
      .required('Mã SKU không bỏ trống'),
    name: yup.string().required('Tên sản phẩm không bỏ trống'),
    type: yup.object().typeError('Loại sản phẩm không bỏ trống').required('Loại sản phẩm không bỏ trống'),
    brand: yup
      .mixed()
      .test('optionIsStringOrObj', 'Thương hiệu không hợp lệ', value => {
        return typeof value === 'string' || typeof value === 'object';
      })
      .required('Thương hiệu không bỏ trống')
      .nullable(),
  });

  const mutationCreateSku = useMutation({
    mutationFn: (params: any) => {
      if (type === 'update') {
        return clientRequest.put(`productsku/${dataSku.id}`, {
          ...params,
        });
      } else {
        return clientRequest.post(`productsku`, {
          ...params,
        });
      }
    },
    onSuccess: () => {
      toast('success', type === 'update' ? 'Chỉnh sửa thành công' : 'Thêm mới thành công');
      refetch();
      reset({ ...defaultValues });
      setShowDrawer(false);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, register, watch } = methods;
  const { errors } = formState;

  const watchcTVPrice = watch('cTVPrice');
  const watchsi_1Price = watch('si_1Price');
  const watchsi_2Price = watch('si_2Price');
  const watchBrand = watch('brand');
  useEffect(() => {
    if (watchBrand?.id) setValue('brandId', watchBrand?.id || null);
  }, [watchBrand]);

  useEffect(() => {
    setValue('cTVPrice', currencyMark(watchcTVPrice));
    setValue('si_1Price', currencyMark(watchsi_1Price));
    setValue('si_2Price', currencyMark(watchsi_2Price));
  }, [watchcTVPrice, watchsi_1Price, watchsi_2Price]);

  useEffect(() => {
    reset({ ...defaultValues });
    setImages(dataSku?.images || []);
  }, [dataSku]);

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
    reset();
  };
  const getUrlFromIMG = async (fromData: any) => {
    setLoadingUpload(true);
    let data = new FormData();
    data.append('images', fromData.file, fromData.file.name);
    const a = await uploadImage(data);
    setLoadingUpload(false);

    return a?.data?.images[0];
  };

  const onChange = async (imageList: any, addUpdateIndex: any) => {
    if (addUpdateIndex) {
      imageList[addUpdateIndex] = await getUrlFromIMG(imageList[addUpdateIndex]);
    }
    setImages(imageList);
  };

  
  const onSubmit = (value: any) => {
    const brandId = dataBrand?.data.find(
      (item: any) => item?.name === value?.brand?.name || item?.name === value?.brand,
    );
    const params: CreateSkuRq = {
      sku: value.sku.trim(),
      name: value.name.trim(),
      user: user.userName,
      brandId: brandId?.id || null,
      brand: value?.brand?.name ? value?.brand?.name : value.brand,
      type: value?.type?.code || dataSku?.type?.code,
      discountCTV: {
        price: numberFormat(value?.cTVPrice),
        quantity: numberFormat(value?.cTVQuantity),
      },
      discountSI_1: {
        price: numberFormat(value?.si_1Price),
        quantity: numberFormat(value?.si_1Quantity),
      },
      discountSI_2: {
        price: numberFormat(value?.si_2Price),
        quantity: numberFormat(value?.si_2Quantity),
      },
      note: value.note,
      images: value?.images && value?.images,
      desctions: value?.desctions,
    };
    mutationCreateSku.mutate(params);
  };

  const goBack = () => {
    setShowDrawer(false);
    reset();
  };

  const onSelectedImage = (image: any) => {
    setSelectedImage(image);
    setOpenModal(true);
  };


  const getTitle = (type: string) => {
    if (type === 'update') {
      return 'Cập nhật';
    } else {
      return 'Thêm mới';
    }
  };

  const checkRole = () => {
    if (user?.roles?.[0] === 1) {
      return false;
    } else if (user?.roles?.[0] === 8 || user?.roles?.[0] === 9) {
      return true;
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>{getTitle(type)}</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>{getTitle(type)} SKU</span>
            </div>
            <div className="content">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControlCustom label={'Mã SKU'} fullWidth>
                    <InputField
                      name={'sku'}
                      placeholder={'Nhập mã'}
                      error={Boolean(errors.sku)}
                      helperText={errors?.sku?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Tên sản phẩm'} fullWidth>
                    <InputField
                      name={'name'}
                      placeholder={'Nhập tên sản phẩm'}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={5}>
                  <FormControlCustom label={'Thương hiệu'} fullWidth>
                    <SelectCustom
                      name={'brand'}
                      placeholder={'Chọn thương hiệu'}
                      error={Boolean(errors.brand)}
                      helperText={errors?.brand?.message as string}
                      options={dataBrand?.data || []}
                      freeSolo
                      isAutoSelected={true}
                      optionLabelKey={'name'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Loại sản phẩm'} fullWidth>
                    <SelectCustom
                      name={'type'}
                      optionLabelKey={'value'}
                      placeholder={'Loại sản phẩm'}
                      error={Boolean(errors.type)}
                      helperText={errors?.type?.message as string}
                      options={dataType?.data || []}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Giá CTV/ Sỉ 1/ Sỉ 2</span>
            </div>
            <div className="content">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControlCustom label={'Cộng tác viên'} fullWidth>
                    <InputField
                      name={'cTVQuantity'}
                      helperText={''}
                      placeholder={'0'}
                      onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      type={'number'}
                      InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Giá giảm'} fullWidth>
                    <InputField
                      name={'cTVPrice'}
                      helperText={''}
                      placeholder={'0'}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={5}>
                  <FormControlCustom label={'Sỉ 1'} fullWidth>
                    <InputField
                      name={'si_1Quantity'}
                      placeholder={'0'}
                      type="number"
                      onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Giá giảm'} fullWidth>
                    <InputField
                      name={'si_1Price'}
                      placeholder={'0'}
                      helperText={''}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={5}>
                  <FormControlCustom label={'Sỉ 2'} fullWidth>
                    <InputField
                      name={'si_2Quantity'}
                      placeholder={'0'}
                      type="number"
                      onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Giá giảm'} fullWidth>
                    <InputField
                      name={'si_2Price'}
                      placeholder={'0'}
                      helperText={''}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                    />
                  </FormControlCustom>
                </Grid>

                <Grid item xs={12}>
                  <FormControlCustom label={'Mô tả'} fullWidth className="ckeditor">
                    <CKEditor
                      {...register('desctions')}
                      config={{ ...custom_config, placeholder: 'Nhập mô tả' }}
                      editor={ClassicEditor}
                      onReady={(editor: any) => {}}
                      onChange={(event: any, editor: any) => {
                        const data = editor?.getData();
                        setValue('desctions', data);
                        // setDataContent(data);
                      }}
                      data={dataSku?.desctions || ''}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Ghi chú</span>
            </div>
            <div className="content">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlCustom label={''} fullWidth className="align-item">
                    <InputField
                      className="input-note"
                      name={'note'}
                      helperText={''}
                      placeholder={'Nhập ghi chú'}
                      rows={3}
                      multiline
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Hình ảnh sản phẩm</span>
            </div>
            <div className="view-image">
              <div className="image-product">
                <UploadImage 
                sortAble 
                onChange={onChange} 
                images={images} 
                isLoading={loadingUpload}   
                setItems={(images: any) => setValue('images', images)}
                openImage={onSelectedImage} />
                  
              </div>
            </div>
            <div className="content"></div>
          </div>
          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <LoadingButton
                  className={'btn-tertiary-normal'}
                  // onClick={onSubmit}
                  type="submit"
                >
                  {'Xác nhận'}
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default CreateStock;
