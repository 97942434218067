import { Button, Divider, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import SearchInput from 'src/shared-components/SearchInput/Index';
import { brandList } from 'src/utils/app-data';
import BrandCreateAndEdit from './components/brandCreateAndEdit';
import BrandList from './components/brandList';

const Brand = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [listRow, setListRow] = useState<any>(brandList);
  const { data } = useQuery({
    queryKey: ['brand'],
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      let tmpDataRow: any = [...listRow];
      tmpDataRow.push(data);

      setListRow(tmpDataRow);
    }
  }, [data]);

  return (
    <div className={'page-layout'}>
      <Grid className={'align-items-center header_title'}>
        <Grid md={7}>
          <h2 className={'txt-title'}>THƯƠNG HIỆU</h2>
        </Grid>
        <div className="item-btn-right">
          <Button onClick={() => setShowDrawer(true)} className={'btn-create'}>
            <span className={'txt'}>Thêm mới</span>
          </Button>
          <Button className={'btn-create'}>
            <span className={'txt'}>Chỉnh sửa</span>
          </Button>
        </div>
      </Grid>
      <Divider />
      {/* TODO SEARCH */}
      <div className="search" style={{ width: '35%' }}>
        <SearchInput className="w-[86%]" placeholder={'Nhập thông tin tìm kiếm'} />
      </div>

      <div className="container-table">
        <BrandList data={listRow || []} />
      </div>
      <BrandCreateAndEdit setShowDrawer={setShowDrawer} showDrawer={showDrawer} />
    </div>
  );
};

export default Brand;
