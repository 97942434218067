import { TextField, TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import './index.scss';

interface IInputFieldProps {
  defaultValue?: string | undefined;
  className?: string | undefined;
  name: string;
  error?: boolean | undefined;
  control?: any;
  helperText?: string | boolean | undefined;
  placeholder?: string | undefined;
  label?: string | undefined;
  disabled?: boolean | undefined;
  style?: React.CSSProperties;
  multiline?: boolean | undefined;
  rows?: number | undefined;
  minRows?: number | undefined;
  maxRows?: number | undefined;
  type?: React.HTMLInputTypeAttribute;
  InputProps?: any;
  value?: any;
  onKeyDown?: any;
  onClick?: any;
  sx?: any;
}

const InputField = (props: IInputFieldProps) => {
  const {
    defaultValue,
    control,
    name,
    value,
    className,
    error = false,
    helperText = undefined,
    label = undefined,
    disabled = false,
    placeholder = undefined,
    multiline = false,
    rows = undefined,
    minRows = undefined,
    maxRows = undefined,
    type = undefined,
    InputProps = undefined,
    onKeyDown,
    onClick,
    sx,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          disabled={disabled}
          className={`${className} input-field font-size-1`}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          type={type}
          defaultValue={defaultValue}
          InputProps={InputProps}
          inputProps={InputProps}
          onKeyDown={onKeyDown}
          onClick={onClick}
          sx={{
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            'input:read-only': {
              color: '#038edb !important',
              cursor: 'default',
            },
          }}
          {...field}
          {...inputProps}
        />
      )}
    />
  );
};

export default InputField;
