export const checkStatusOrder = (status: any) => {
  switch (status) {
    case 'IN_REVIEW':
      return {
        status: 'Chờ duyệt',
        backgroundColor: '#F8AF3F',
      };
    case 'IN_PROCESS':
      return {
        status: 'Đang GD',
        backgroundColor: '#1A89AC',
      };
    case 'DONE':
      return {
        status: 'Hoàn thành',
        backgroundColor: '#17742E',
      };
    case 'CANCEL':
      return {
        status: 'Đã hủy',
        backgroundColor: '#F05050',
      };
  }
};

export const checkStatusShipping = (status: any) => {
  switch (status) {
    case 'IN_PROCESS':
      return {
        status: 'Chờ giao hàng',
      };
    case 'IN_PICKUP':
      return {
        status: 'Đã soạn hàng',
      };
    case 'PICKUP_SUCCESS':
      return {
        status: 'Đã giao shipper',
      };
    case 'IN_TRANSIT':
      return {
        status: 'Đang vận chuyển',
      };
    case 'DELIVERED':
      return {
        status: 'Đã giao hàng',
      };
    case 'CANCEL':
      return {
        status: 'Đã hủy',
      };
    case 'REFUNDED':
      return {
        status: 'Đã hoàn hàng',
      };
    case 'IN_PROCESS_REFUND':
      return {
        status: 'Đang hoàn hàng',
      };
  }
  return { status: 'Không xác định' };
};

export const checkStatusOrderDetail = (status: any) => {
  switch (status) {
    case 'IN_DELIVERY':
      return {
        status: 'Đang giao hàng',
        backgroundColor: '#1A89AC',
      };
    case 'IN_PICKUP':
      return {
        status: 'Đã soạn hàng',
        backgroundColor: '#1A89AC',
      };
    case 'IN_PROCESS':
      return {
        status: 'Chờ giao hàng',
        backgroundColor: '#F8AF3F',
      };
    case 'DELIVERED':
      return {
        status: 'Đã giao hàng',
        backgroundColor: '#17742E',
      };
    case 'REFUND':
      return {
        status: 'Đã hoàn hàng',
        backgroundColor: '#F05050',
      };
    case 'REFUND_SOME_PROCESS':
      return {
        status: 'Đang hoàn hàng một phần',
        backgroundColor: '#F05050',
      };
    case 'REFUND_PROCESS':
      return {
        status: 'Đang hoàn hàng',
        backgroundColor: '#F05050',
      };
    case 'REFUND_SOME':
      return {
        status: 'Đã hoàn một phần',
        backgroundColor: '#F8AF3F',
      };
  }
  return { status: 'Không xác định' };
};

export enum ShippingType {
  REFUND = 'REFUND',
}
