import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { isEmpty } from 'lodash';
import noData from 'src/assets/no-data.png';
import '../Table/Table.scss';
import { Box } from '@mui/material';
interface Column {
  id: 'date' | 'event' | 'user' | 'object' | 'image' | 'act';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'date', label: 'Ngày / Giờ', minWidth: 70 },
  { id: 'event', label: 'Hoạt động', minWidth: 70 },
  { id: 'act', label: 'Hành động', minWidth: 100 },
  {
    id: 'object',
    label: 'Đối tượng',
    minWidth: 70,
    align: 'right',
  },
  {
    id: 'user',
    label: 'Tài khoản',
    minWidth: 100,
    align: 'right',
  },
];

export default function StickyHeadTable(props: any) {
  const { data, total, handleChangeRowsPerPage, handleChangePage, page, pageSize, totalPage } = props;

  // React.useEffect(() => {
  //   const content = document.querySelector('.MuiTablePagination-selectLabel');
  //   if (content) {
  //     content.textContent = 'Số hàng hiển thị:';
  //   }
  // }, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} style={{ border: '1px solid #e4e4e4' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            style={{
              border: '1px solid #e4e4e4',
              backgroundColor: '#fafafa',
              fontWeight: '600',
              fontSize: '14px',
              height: '32px',
              padding: '7px',
              textAlign: 'center',
            }}
          >
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} className="item-colum">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((data: any) => {
              return (
                <TableRow className="row" hover key={data?.id}>
                  <TableCell className="item-row">
                    {moment(data?.createdAt)?.format('DD/MM/YYYY - h:mm:ss A')}
                  </TableCell>
                  <TableCell className="item-row ">
                    <span className={data?.method === 'CREATE' ? 'bg-create' : 'bg-update'}>{data?.method ?? ''}</span>
                  </TableCell>
                  <TableCell className="item-row">{data?.act ?? ''}</TableCell>
                  <TableCell className="item-row">{data?.code ?? ''}</TableCell>
                  <TableCell className="item-row">{data?.user ?? ''}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {isEmpty(data) && (
          <div className={'btn-add-product'}>
            <div className={'view-btn-add'}>
              <img src={noData} className="empty-data" />
              <span>Không có log</span>
            </div>
          </div>
        )}
      </TableContainer>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <p>Chuyển nhanh đến trang: </p>

        <input
          value={page + 1}
          min={1}
          onChange={event => {
            handleChangePage(event, Number(event.target.value) - 1);
          }}
          style={{ width: 50, marginLeft: 20, textAlign: 'center' }}
        />

        <p style={{ padding: '0px 12px' }}>Tổng số trang: {totalPage}</p>
      </div>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 100]}
        component="div"
        count={total || 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Số hàng hiển thị'}
      />
    </Paper>
  );
}
