import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './component/header';

import LeftSliderBar from './component/leftSliderBar';
import './defaultLayout.scss';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

const drawerWidth = 210;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#00354e',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: '#00354e',
  justifyContent: 'space-between',
  alignContent: 'center',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: 'white',
}));

const DefaultLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [state, setState] = useState<any>();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const updateState = window.addEventListener('resize', setState);

  const width = document.documentElement.clientWidth;

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [location]);

  useEffect(() => {
    if (document.documentElement.clientWidth < 800) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width]);

  return (
    <Grid sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header AppBar={AppBar} openDrawer={open} handleDrawerOpen={() => handleDrawer()} />
      <LeftSliderBar
        theme={theme}
        Drawer={Drawer}
        DrawerHeader={DrawerHeader}
        handleDrawerClose={handleDrawer}
        open={open}
      />
      <Grid className="content-right" component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <div className={`content-layout ${open ? 'drawer-expand' : 'drawer-collapse'}`}>
          <Outlet />
        </div>
      </Grid>
    </Grid>
  );
};

export default DefaultLayout;
