import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteSku, getDetailSku, getSku, importSku } from 'src/services/sku.services';

import './sku.scss';
import SKUList from './components/skuList';
import ModalAlert from 'src/shared-components/Modal';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
import SKUCreateAndEdit from './components/skuCreateAndEdit';
import { updateProductSku } from 'src/services/stock.services';
import SearchInput from 'src/shared-components/SearchInput/Index';
import LoadingCircle from 'src/shared-components/LoadingCircle';
import { useLocation } from 'react-router';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import SelectCustom from 'src/shared-components/SelectCustom';
import { inPreview, status } from 'src/utils/app-data';
import { FormProvider, useForm } from 'react-hook-form';
import { getBrand, getType } from 'src/services/app.services';

const SKU = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const paramType = useLocation();

  const [page, setPage] = useState<number>(0);
  const [idSku, setIdSku] = useState<any>(null);
  const [urlFile, setUrlFile] = useState<string>();
  const [openModal, setOpenModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [formType, setFormType] = useState<any>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<any>('');
  const [selectedSku, setSelectedSku] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<any>(null);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openModalImport, setModalImport] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchValue, 500);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const detailSku = useQuery(['detailSku', idSku], () => getDetailSku(idSku), {
    keepPreviousData: true,
    enabled: showDrawer,
    staleTime: 5000,
  });

  const { data: dataProduct, refetch } = useQuery(['sku', { ...filterParams, page, pageSize }], (params: any) =>
    getSku({ page: page + 1, pageSize: pageSize, ...filterParams }),
  );

  const { data: dataBrand } = useQuery(['dataBrand'], () => getBrand({}), { enabled: true, keepPreviousData: true });

  const { data: dataType } = useQuery(['type'], () => getType({}), { enabled: true });

  const defaultValues = {
    status: status[2],
    brand: null,
    type: null,
    inPreview: null,
  };

  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;
  const watchStatus = watch('status');
  const watchBrand: any = watch('brand');
  const watchType: any = watch('type');
  const watchInPreview: any = watch('inPreview');

  const mutationDeleteSku = useMutation({
    mutationFn: deleteSku,
    onSuccess: (data: any) => {
      toast('success', 'Xóa thành công');
      setOpenModal(false);
      refetch();
      setSelected([]);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationUpdateSku = useMutation({
    mutationFn: updateProductSku,
    onSuccess: () => {
      toast('success', 'Cập nhật thành công');
      refetch();
      setShowDrawer(false);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationImportSku = useMutation({
    mutationFn: importSku,
    onSuccess: (data: any) => {
      toast('success', 'Thêm thành công');
      setModalImport(false);

      refetch();
      setUrlFile('');
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    const tmpSelected: any = [];
    if (!isEmpty(selected)) {
      selected.map((item: any) =>
        dataProduct?.data.data.map((sku: any) => {
          if (item === sku?.id) {
            tmpSelected.push(sku);
            setSelectedSku(tmpSelected);
          }
        }),
      );
    } else {
      setSelectedSku([]);
    }
  }, [selected]);

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch });
  }, [debouncedSearch]);

  useEffect(() => {
    const params = {
      status: watchStatus?.codeName,
      brand: watchBrand?.id,
      type: watchType?.code,
      inPreview: watchInPreview?.preview,
    };

    setFilterParams({ ...filterParams, ...params });
  }, [watchStatus, watchBrand, watchType, watchInPreview]);

  useEffect(() => {
    if (!showDrawer) {
      setIdSku('');
    }
  }, [showDrawer]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = dataProduct?.data?.data.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handelShowDetail = (id: string) => {
    setShowDrawer(true);
    setIdSku(id);
    setFormType('update');
  };

  useEffect(() => {
    if (paramType?.state?.id) {
      setShowDrawer(true);
      setIdSku(paramType?.state?.id);
      setFormType('update');
    }
  }, [paramType?.state?.id]);

  const handleInPreview = (row: any) => {
    if (row?.inPreview) {
      const params = {
        inPreview: false,
        id: row?.id,
        user: user?.userName,
        sku: row?.sku,
      };
      mutationUpdateSku.mutate(params);
    } else {
      const params = {
        inPreview: true,
        id: row?.id,
        user: user?.userName,
        sku: row?.sku,
      };
      mutationUpdateSku.mutate(params);
    }
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.currentTarget.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModalImport = () => {
    setModalImport(false);
  };

  const handleOpenModal = () => {
    if (!isEmpty(selected)) {
      setOpenModal(true);
    } else {
      toast('warning', 'Vui lòng chọn mã');
    }
  };
  const handleOpenCreate = () => {
    setShowDrawer(true);
    setFormType('create');
  };

  const getUrlFromIMG = async (fromData: any) => {
    const data = new FormData();
    data.append('file', fromData);
    data.append('user', user?.userName);

    const a = await mutationImportSku.mutate(data);

    // setUrlFile(a?.data?.files[0].Location);
  };

  const onChange = (event: any) => {
    // data for submit
    // funcUpload(event.target.files[0]);
    setUrlFile(event.target.files[0]);
  };

  const funcUpload = async (image: any) => {
    function readFileAsync() {
      return new Promise((resolve, reject) => {
        const file = image;
        getUrlFromIMG(file).then((response: any) => {
          if (!response) {
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: response?.data?.id,
              url: response?.data?.url,
              name: response?.data?.name,
              type: 'file',
            });
          };
          reader.onerror = reject;
          // reader.readAsBinaryString(file);
        });
      });
    }
    await readFileAsync();
  };

  const handleConfirm = () => {
    const params = {
      ids: selected,
    };
    mutationDeleteSku.mutate(params);
  };

  const onImportSku = () => {
    funcUpload(urlFile);
  };
  return (
    <div className={'page-layout'}>
      <Grid container className={'align-items-center header_title'}>
        <Grid item md={7}>
          <h2 className={'txt-title'}>QUẢN LÝ SKU</h2>
        </Grid>
        <Grid item md={5}>
          <Box className={'header-button'}>
            <Button onClick={() => setModalImport(true)} className={'btn-create'}>
              <span className={'txt'}>Import</span>
            </Button>
            <Button onClick={() => handleOpenCreate()} className={'btn-create'}>
              <span className={'txt'}>Tạo SKU</span>
            </Button>
            <Button onClick={() => handleOpenModal()} className={'btn-create'}>
              <DeleteOutlineIcon /> <span className={'txt'}>Xóa</span>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <FormProvider {...methods}>
        <Grid container spacing={3} className="search">
          <Grid item md={3} display="flex" flexDirection="column" gap="7px" sx={{ paddingTop: '0px !important' }}>
            <span className="txt-find">Tìm kiếm</span>
            <SearchInput
              placeholder={'Tìm theo tên sản phẩm, mã sản phẩm, mã SKU'}
              value={searchValue}
              setSearchValue={setSearchValue}
              handleSearch={handleSearch}
            />
          </Grid>

          <Grid item md={8} sx={{ paddingTop: '0px !important' }}>
            <Box style={{ display: 'flex', justifyContent: 'flex-start' }} flexDirection={{ xs: 'column', md: 'row' }}>
              <FormControlCustom label="Tình trạng" fullWidth>
                <div className="view-input">
                  <SelectCustom
                    options={status}
                    placeholder={'Tất cả'}
                    name={'status'}
                    isOptionEqualToValue={(option: any, value: any) => option?.codeName === value?.codeName}
                  />
                </div>
              </FormControlCustom>

              <FormControlCustom label="Thương hiệu" fullWidth>
                <div className="view-input">
                  <SelectCustom options={dataBrand?.data} placeholder={'Tất cả'} name={'brand'} />
                </div>
              </FormControlCustom>

              <FormControlCustom label="Loại sản phẩm" fullWidth>
                <div className="view-input">
                  <SelectCustom
                    options={dataType?.data || []}
                    placeholder={'Tất cả'}
                    name={'type'}
                    optionLabelKey="value"
                  />
                </div>
              </FormControlCustom>

              <FormControlCustom label="Bán hàng" fullWidth>
                <div className="view-input">
                  <SelectCustom
                    options={inPreview}
                    placeholder={'Tất cả'}
                    name={'inPreview'}
                    isOptionEqualToValue={(option: any, value: any) => option?.preview === value?.preview}
                  />
                </div>
              </FormControlCustom>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>

      <div className="container-table">
        <SKUList
          data={dataProduct?.data?.data || []}
          handleShowDetail={handelShowDetail}
          selectionModel={selected}
          handleSelectionModeChange={handleSelectAllClick}
          handleClick={handleClick}
          handleChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          total={dataProduct?.data?.total}
          page={page}
          pageSize={pageSize}
          handleInPreview={handleInPreview}
        />
      </div>
      <SKUCreateAndEdit
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
        dataSku={detailSku?.data?.data}
        type={formType}
        refetch={refetch}
      />
      <ModalAlert
        open={openModal}
        handleClose={() => handleCloseModal()}
        handleCancel={() => handleCloseModal()}
        handleConfirm={() => handleConfirm()}
        title={'Xác nhận xóa'}
        description={'Thao tác sẽ không thể hoàn tác, bạn có chắc chắn muốn tiếp tục không?'}
        type={'error'}
        icon={true}
        renderContentModal={
          <div className="view-input-discount">
            <div style={{ width: '55px' }}>
              <span>Mã sku:</span>
            </div>
            <div style={{ wordWrap: 'break-word' }}>
              <div
                style={{
                  width: '240px',
                  flexDirection: 'row',
                  marginLeft: '5px',
                }}
              >
                {selectedSku?.map((sku: any, index: number) => {
                  return (
                    <span key={index}>
                      {sku?.sku}
                      {index + 1 < selectedSku.length && ', '}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        }
      />

      <ModalAlert
        open={openModalImport}
        handleClose={() => handleCloseModalImport()}
        handleCancel={() => handleCloseModalImport()}
        handleConfirm={() => onImportSku()}
        title={'Chọn file '}
        isLoading={mutationImportSku?.isLoading}
        description={''}
        icon={true}
        renderContentModal={
          <div className="view-input-discount">
            <input type="file" onChange={onChange} name="file" />
          </div>
        }
      />
    </div>
  );
};

export default SKU;
