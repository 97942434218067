import clientRequest from 'src/config/axios';
import { CreateOrderRq } from 'src/model/order.modal';

export const getOrder = async (params: any) => {
  const { time, ...filteredParams } = params;
  const res = await clientRequest.get(`orders`, {
    params: {
      ...filteredParams,
    },
  });
  return res;
};

export const createOrder = async (params: CreateOrderRq) => {
  const res = await clientRequest.post(`orders`, {
    ...params,
  });
  return res;
};

// UPDATE: thêm params truyền đi để chỉnh sửa số lượng product trong kho
export const changeQuantityItem = async (params: any) => {
  const { idSkuProduct, idItem, quantity, skuQuantity, initialQuantity } = params;
  const res = await clientRequest.put(`orders/${idSkuProduct}/itemsQuantity/${idItem}`, {
    quantity,
    skuQuantity,
    initialQuantity,
  });
  return res;
};

export const updateOrder = async (params: any) => {
  const res = await clientRequest.put(`orders/${params?.id}`, {
    ...params,
  });
  return res;
};

export const getOrderDetail = async (params: any) => {
  const res = await clientRequest.get(`orders/${params}`);
  return res;
};

export const createOrderCustomer = async (params: any) => {
  const res = await clientRequest.post(`orders/customer`, {
    ...params,
  });
  return res;
};

export const getOrderCustomer = async (params: any) => {
  const res = await clientRequest.get('orders/search/customer', {
    params: {
      ...params,
    },
  });
  return res;
};

export const createOrderPayment = async (params: any) => {
  const res = await clientRequest.post(`orders/${params.id}/payment`, {
    ...params,
  });
  return res;
};

export const createOrderTracking = async (params: any) => {
  const res = await clientRequest.post(`orders/${params.id}/tracking`, {
    ...params,
  });
  return res;
};

export const getTracking = async (params: any) => {
  const res = await clientRequest.get(`tracking`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const getSearchProduct = async (params: any) => {
  const res = await clientRequest.get(`orders/search/products`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const getOrderTracking = async (params: any) => {
  const res = await clientRequest.get(`orders/${params}/tracking`);
  return res;
};

export const deleteTracking = async (params: any) => {
  const res = await clientRequest.delete(`orders/tracking/cancel`, {
    data: {
      ...params,
    },
  });
  return res;
};

export const updateOrderTracking = async (params: any) => {
  const { data } = params;
  const res = await clientRequest.put(`orders/${params?.id?.orderId}/tracking/${params?.id?.trackingId}`, {
    ...data,
  });
  return res;
};

export const cancelOrder = async (params: any) => {
  const res = await clientRequest.delete(`orders/${params.id}/cancel`, {
    data: {
      ...params,
    },
  });
  return res;
};

export const removeProductInOrder = async (params: any) => {
  const { id, items } = params;
  const res = await clientRequest.post(`orders/${params.id}/product/remove`, {
    items,
  });
  return res;
};

export const addProductInOrder = async (params: any) => {
  const { id, productId, quantity, note, user } = params;
  const res = await clientRequest.post(`orders/${params.id}/product`, {
    productId,
    quantity,
    note,
    user,
  });
  return res;
};

export const getRefund = async (params: any) => {
  const res = await clientRequest.get(`refund/list`);
  return res;
};

export const approveRefund = async (params: any) => {
  const { id, branch, user } = params;
  const res = await clientRequest.post(`refund/${id}/approve`, { branch, user });
  return res;
};

export const getItemRefund = async (params: any) => {
  const res = await clientRequest.get(`orders/${params}/items/refund`);
  return res;
};

export const createItemRefund = async (params: any) => {
  const res = await clientRequest.post(`orders/${params?.id}/items/refund`, {
    ...params,
  });
  return res;
};

export const updateOrderRefund =
  (orderShippingId: string, shippingId: string) =>
  (
    body: Partial<{
      refundFee: number;
      branch: string;
      note: string;
      reason: string;
      deliveryId: string;
      deliveryTrucking: string;
    }>,
  ) => {
    return clientRequest.put(`orders/${orderShippingId}/refund/${shippingId}`, body);
  };

export const changeNoteItem = async (params: any) => {
  const { id, idItem, note } = params;
  const res = await clientRequest.put(`orders/${id}/items/${idItem}`, {
    note: note,
  });
};

export const downloadExcel = async (params: any) => {
  const res = await clientRequest.get(`export/order`, {
    responseType: 'blob',
    params: {
      ...params,
    },
  });
  return res;
};
