import { Divider, Grid } from '@mui/material';
import StickyHeadTable from './components/Table/Table';
import './diary.scss';
import { getDiary } from 'src/services/log.services';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import SelectCustom from 'src/shared-components/SelectCustom';
import { getUsers } from 'src/services/app.services';
import { FormProvider, useForm } from 'react-hook-form';
import SearchInput from 'src/shared-components/SearchInput/Index';
import useDebounce from 'src/shared-components/debounce';

const Diary = () => {
  const [filterParams, setFilterParams] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [ignore, setIgnore] = useState<boolean>(false); // Biến ignore
  const [prevPageSize, setPrevPageSize] = useState<number>(0);
  const [prevSearchValue, setPrevSearchValue] = useState<string>('');
  const debouncedSearch = useDebounce(searchValue, 500);
  const defaultValues = {
    user: null,
  };

  const methods = useForm({
    defaultValues,
  });

  // const { watch } = methods;

  // const watchUser: any = watch('user');

  const { data: dataDiary, refetch } = useQuery(['diary', { ...filterParams, page, pageSize }], (params: any) =>
    getDiary({ page: page + 1, pageSize: pageSize, ...filterParams }),
  );

  const { data: dataUser } = useQuery(['users'], () => getUsers({}));
  const [data, setData] = useState(dataDiary?.data?.data);

  const handleChange = (e: any) => {
    const keyWord = e.target.value.toLowerCase().trim();
    let newData = dataDiary?.data?.data?.filter(
      (item: any) => item.user?.toLowerCase()?.includes(keyWord) || item?.code?.toLowerCase()?.includes(keyWord),
    );
    setData(newData);
  };

  useEffect(() => {
    setData(dataDiary?.data?.data);
    setTotalPage(Math.ceil(dataDiary?.data?.total / pageSize));
  }, [debouncedSearch, dataDiary]);

  const handleSearch = (e: any) => {
    setSearchValue(e.currentTarget.value);
  };
  useEffect(() => {
    if (prevPageSize !== pageSize) {
      setPrevPageSize(pageSize);
    } else {
      if (prevSearchValue !== searchValue) {
        setPrevSearchValue(searchValue);
        setIgnore(false);
      } else {
        if (dataDiary?.data?.total !== undefined) {
          if (ignore === false) {
            setTotalPage(Math.ceil(dataDiary?.data?.total / pageSize));
            setIgnore(true);
          }
        }
      }
    }
  }, [dataDiary, pageSize, selectedUser]);

  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      setFilterParams({ ...filterParams, search: searchValue, userAssigned: selectedUser.id });
    } else {
      setFilterParams({ ...filterParams, search: searchValue, userAssigned: null });
    }
  }, [page, searchValue, selectedUser]);

  useEffect(() => {
    setData(dataDiary?.data?.data);
  }, [dataDiary]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
    setIgnore(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className={'page-layout'}>
      <Grid container className={'align-items-center header_title'}>
        <Grid item md={7}>
          <h2 className={'txt-title'}>Nhật Ký</h2>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className="search">
        <FormProvider {...methods}>
          <Grid item md={12} lg={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={2.7}>
                <FormControlCustom label="Tìm kiếm" fullWidth>
                  <SearchInput
                    className="w-[86%]"
                    placeholder={'Nhập thông tin tìm kiếm'}
                    value={searchValue}
                    setSearchValue={setSearchValue}
                    handleSearch={handleSearch}
                  />
                </FormControlCustom>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2.7}>
                <FormControlCustom label="Nhân viên phụ trách" fullWidth>
                  <div className="view-input">
                    <SelectCustom
                      options={dataUser?.data}
                      placeholder={'Tất cả'}
                      name={'user'}
                      freeSolo={true}
                      onChange={(e,v)=>setSelectedUser(v)}
                    />
                  </div>
                </FormControlCustom>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
      <StickyHeadTable
        data={data}
        total={dataDiary?.data?.total}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        pageSize={pageSize}
        totalPage={totalPage}
      />
    </div>
  );
};

export default Diary;
