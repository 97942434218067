import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import '../../../layout/defaultLayout.scss';
import clientRequest from 'src/config/axios';
import { stringCompare } from 'src/utils/utils';
import { uploadImage } from 'src/services/upload';
import { getType } from 'src/services/app.services';
import { useToast } from 'src/shared-components/Toast';
import InputField from 'src/shared-components/InputField';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import UploadIcon from 'src/shared-components/UploadImage/UploadIcon';

const TypeCreateEdit = (props: any) => {
  const toast = useToast();
  const { setShowDrawer, showDrawer, data, type, refetch } = props;

  const [images, setImages] = useState<any>([]);
  const [urlImage, setUrlImage] = useState<any>();
  const [imagesShipUs, setImagesShipUs] = useState<any>([]);
  const [urlImageShipUs, setUrlImageShipUs] = useState<any>();

  const defaultValues = useMemo(
    () => ({
      code: data?.code || '',
      value: data?.value || '',
      image: data?.icon || '',
      imageShipUs: data?.iconShipUs,
    }),
    [data],
  );

  const schema = yup.object().shape({
    code: yup
      .string()
      .matches(/^\S*$/, 'Mã không chưa khoảng trắng')
      .matches(/^[A-Za-z0-9]*$/, 'Không chứa kí tự đặc biệt')
      .required('Mã không được phép bỏ trống'),
    value: yup.string().required('Tên loại không được phép bỏ trống'),
    image: yup.string().required('Vui lòng chọn icon loại sản phẩm').nullable(),
    imageShipUs: yup.string().required('Vui lòng chọn icon loại sản phẩm').nullable(),
  });

  const mutationCreateSku = useMutation({
    mutationFn: (params: any) => {
      if (type === 'update') {
        return clientRequest.put(`type/${data.code}`, {
          ...params,
        });
      } else {
        return clientRequest.post(`type`, {
          ...params,
        });
      }
    },
    onSuccess: () => {
      toast('success', type === 'update' ? 'Cập nhật thành công' : 'Thêm mới thành công');
      refetch();
      reset({ ...defaultValues });
      setShowDrawer(false);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch } = methods;
  const { errors } = formState;

  const watchCode = watch('code');

  useEffect(() => {
    reset({ ...defaultValues });
    setImages([data?.icon] || []);
    setUrlImage(data?.icon || []);
    setUrlImageShipUs(data?.iconShipUs || []);
    setImagesShipUs([data?.iconShipUs] || []);
  }, [data]);

  useEffect(() => {
    if (!isEmpty(data?.code)) {
      setValue('code', stringCompare(watchCode));
    }
  }, [watchCode]);

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
    reset({ ...defaultValues });
  };

  const onChange = (imageList: any) => {
    // data for submit
    funcUpload(imageList);
    setImages(imageList);
  };
  const onChangeShipUs = (imageList: any) => {
    // data for submit
    funcUploadShipUs(imageList);
    setImagesShipUs(imageList);
  };

  const getUrlFromIMG = async (fromData: any) => {
    let data = new FormData();
    data.append('images', fromData[0].file, fromData[0].file.name);

    const a = await uploadImage(data);

    setUrlImage(a?.data?.images?.[0]);
    setValue('image', a?.data?.images?.[0]);
  };

  const getUrlFromIMGShipUs = async (fromData: any) => {
    let data = new FormData();
    data.append('images', fromData[0].file, fromData[0].file.name);

    const a = await uploadImage(data);

    setUrlImageShipUs(a?.data?.images?.[0]);
    setValue('imageShipUs', a?.data?.images?.[0]);
  };

  const funcUpload = async (image: any) => {
    function readFileAsync() {
      return new Promise((resolve, reject) => {
        const file = image;
        getUrlFromIMG(file).then((response: any) => {
          if (!response) {
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: response?.data?.id,
              url: response?.data?.url,
              name: response?.data?.name,
              type: 'image',
            });
          };
          reader.onerror = reject;
        });
      });
    }
    await readFileAsync();
  };

  const funcUploadShipUs = async (image: any) => {
    function readFileAsync() {
      return new Promise((resolve, reject) => {
        const file = image;
        getUrlFromIMGShipUs(file).then((response: any) => {
          if (!response) {
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: response?.data?.id,
              url: response?.data?.url,
              name: response?.data?.name,
              type: 'image',
            });
          };
          reader.onerror = reject;
        });
      });
    }
    await readFileAsync();
  };

  const onRemove = () => {
    setUrlImage('');
    setValue('image', '');
  };

  const onRemoveShipUs = () => {
    setUrlImageShipUs('');
    setValue('imageShipUs', '');
  };

  const onSubmit = (value = defaultValues) => {
    const params: any = {
      code: value.code,
      value: value.value,
      icon: urlImage,
      iconShipUs: urlImageShipUs,
    };
    mutationCreateSku.mutate(params);
  };

  const goBack = () => {
    setShowDrawer(false);
    reset({ ...defaultValues });
    setImages([]);
  };

  const getTitle = (type: string) => {
    if (type === 'update') {
      return 'Cập nhật';
    } else {
      return 'Thêm mới';
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>{getTitle(type)}</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>{getTitle(type)} Loại sản phẩm</span>
            </div>
            <div className="content">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControlCustom label={'Mã loại sản phẩm'} fullWidth>
                    <InputField
                      name={'code'}
                      placeholder={'Nhập mã'}
                      error={Boolean(errors.code)}
                      helperText={errors?.code?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={7}>
                  <FormControlCustom label={'Tên loại sản phẩm'} fullWidth>
                    <InputField
                      name={'value'}
                      placeholder={'Nhập loại'}
                      error={Boolean(errors.value)}
                      helperText={errors?.value?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Hình ảnh loại sản phẩm Basso</span>
            </div>

            <div className="view-image">
              <div className="image-product">
                <UploadIcon onChange={onChange} images={!isEmpty(urlImage) ? images : ''} onRemove={onRemove} />
              </div>
              {errors.image && (
                <span style={{ fontSize: '0.875rem', color: 'red' }}>{errors?.image?.message as string}</span>
              )}
            </div>
            <div className="title-group">
              <span>Hình ảnh loại sản phẩm ShipUs</span>
            </div>
            <div className="view-image">
              <div className="image-product">
                <UploadIcon
                  onChange={onChangeShipUs}
                  images={!isEmpty(urlImageShipUs) ? imagesShipUs : ''}
                  onRemove={onRemoveShipUs}
                />
              </div>
              {errors.imageShipUs && (
                <span style={{ fontSize: '0.875rem', color: 'red' }}>{errors?.imageShipUs?.message as string}</span>
              )}
            </div>

            <div className="content"></div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <LoadingButton
                  className={'btn-tertiary-normal'}
                  // onClick={onSubmit}
                  type="submit"
                >
                  {'Xác nhận'}
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default TypeCreateEdit;
