import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { IMenu } from '../model/menu.model';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
export const menu = () => {
  const menuData = [
    {
      text: 'Tổng quan',
      icon: <DashboardIcon />,
      path: '/dashboard',
      pathName: '/dashboard',
    },
    {
      text: 'Quản lý hàng stock',
      icon: <Inventory2Icon />,
      path: '/stock-manager',
      pathName: '/stock-manager',
    },
    {
      text: 'Quản lý SKU',
      icon: <ProductionQuantityLimitsIcon />,
      path: '/sku-manager',
      pathName: '/sku-manager',
    },
    {
      text: 'Danh sách đơn hàng',
      icon: <ListAltIcon />,
      path: '/order',
      pathName: '/order',
      renderChildren: false,
      children: [
        {
          path: 'detail',
          text: 'Chi tiết đơn hàng',
          render: false,
        },
      ],
    },
    {
      text: 'Tạo đơn hàng',
      icon: <AddBusinessIcon />,
      path: '/create-order',
      pathName: '/create-order',
    },
    {
      text: 'Nhật ký hoạt động',
      icon: <MenuBookIcon />,
      path: '/diary',
      pathName: '/diary',
    },
    {
      text: 'Cài đặt',
      icon: <SettingsOutlinedIcon />,
      path: '/setting',
      pathName: '/setting',
    },
    // {
    //   text: 'Chỉnh sửa thương hiệu',
    //   icon: <DriveFileRenameOutlineIcon />,
    //   path: '/edit-brand',
    //   pathName: '/edit-brand',
    // },
  ];
  return menuData;
};
