import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import { Collapse, List } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from 'src/assets/img/logo.png';
import { logout, menu } from 'src/services';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import '../defaultLayout.scss';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

interface ILeftSlideBarProps {
  handleDrawerClose: () => void;
  theme: any;
  Drawer: any;
  DrawerHeader: any;
  open: boolean;
}

const LeftSliderBar = (props: ILeftSlideBarProps) => {
  const { Drawer, DrawerHeader, open, handleDrawerClose } = props;
  let navigate = useNavigate();
  const [curState, setCurState] = useState<any>({});

  const handleClick = (e: any) => {
    setCurState({ [e.path]: !curState[e.path] });
    navigate(e.path);
  };
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  return (
    <Drawer className={'left-slider-bar'} variant="permanent" open={open}>
      <List>
        <DrawerHeader st>
          <img alt="bassoLogo" onClick={() => navigate('./dashboard')} src={logo}></img>
        </DrawerHeader>
        {menu().map((item: any, index: number) => {
          if (!isEmpty(user)) {
            if (!(item?.text === 'Cài đặt' && user?.roles?.indexOf(1) === -1)) {
              return (
                <ListItem
                  className={`${window.location.href.includes(item.path) ? 'active' : ''}`}
                  disablePadding
                  sx={{ display: 'block' }}
                  key={index}
                >
                  {item.children != null && item.renderChildren != false ? (
                    <>
                      (
                      <NavLink
                        to={`${item.path}/${item.children[0].path}`}
                        className={({ isActive }) =>
                          isActive ? 'background-before-actived' : 'background-before-inactive'
                        }
                      >
                        <ListItemButton
                          onClick={() => handleClick(item)}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 1.5 : 'auto',
                              justifyContent: 'center',
                            }}
                            className="txt-menu-left"
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={{ opacity: open ? 1 : 0 }}
                            className="txt-menu-left"
                          ></ListItemText>

                          {curState[item.path] ? (
                            <KeyboardArrowUpOutlinedIcon style={{ color: 'white' }} />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon style={{ color: 'white' }} />
                          )}
                        </ListItemButton>
                      </NavLink>
                      )
                      {/* <Collapse component="li" in={curState[item.path]} timeout="auto" unmountOnExit>
                            <ListItem disablePadding>
                              {item?.children?.map((sitem: any, index: number) => {
                                return (
                                  <div key={index}>
                                    {sitem.render != false && (
                                      <ListItem sx={{ pl: 4, lineHeight: '20px' }}>
                                        <NavLink
                                          className={({ isActive }) =>
                                            isActive ? 'dot-before-actived' : 'dot-before-inactive'
                                          }
                                          to={item.path + '/' + sitem.path}
                                        >
                                          {sitem.text}
                                        </NavLink>
                                      </ListItem>
                                    )}
                                  </div>
                                );
                              })}
                            </ListItem>
                          </Collapse> */}
                    </>
                  ) : (
                    <ListItemButton
                      onClick={() => {
                        navigate(item.path);
                        setCurState(false);
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      href={`${item.path}`}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1.5 : 'auto',
                          justifyContent: 'center',
                        }}
                        className="txt-menu-left"
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                        className="txt-menu-left"
                      ></ListItemText>
                    </ListItemButton>
                  )}
                </ListItem>
              );
            }
          }
        })}
        {isMobile && (
          <ListItemButton
            // onClick={() => handleClick(item)}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.5 : 'auto',
                justifyContent: 'center',
              }}
              className="txt-menu-left"
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ opacity: open ? 1 : 0 }}
              className="txt-menu-left"
              onClick={() => {
                logout();
                navigate('/login');
              }}
            >
              Đăng xuất
            </ListItemText>
          </ListItemButton>
        )}
      </List>
      <IconButton onClick={handleDrawerClose} className="view-toggle">
        {open && <PlaylistPlayRoundedIcon className="icon-menu-left" />}
      </IconButton>
    </Drawer>
  );
};

export default LeftSliderBar;
