import { Button, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';

import './index.scss';
import { convertCurrency } from 'src/utils/utils';
import errorImg from 'src/assets/img/error-img.png';
import DataTableV2 from 'src/shared-components/DataTableV2';
import DoneIcon from '@mui/icons-material/Done';
import { checkNameBranch } from 'src/functions/expand-header';

const RefundList = (props: any) => {
  const {
    data,
    selectionModel,
    handleSelectionModeChange,
    handleShowDetail,
    handleClick,
    onChangeRowsPerPage,
    handleChangePage,
    total,
    page,
    handleInPreview,
    pageSize,
    onApprove,
  } = props;

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const columns: any = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'branch',
      headerName: 'Chi nhánh',
      flex: 90,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return <div>{checkNameBranch(params?.row?.branch)?.branch}</div>;
      },
    },
    {
      field: 'orderNumber',
      flex: 100,
      headerName: 'Mã đơn stock',
      headerAlign: 'center',
      headerClassName: 'theme',
      // renderCell: (params: any) => {
      //   return (
      //     <div style={{ marginLeft: '5px', marginRight: '5px', textAlign: 'start' }}>
      //       <div>{params?.row.codeStock}</div>
      //     </div>
      //   );
      // },
    },
    {
      field: 'image',
      headerName: 'Ảnh stock',
      flex: 70,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={params.row.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              style={{ aspectRatio: 1, width: '60px', backgroundSize: 'cover' }}
            />
          </div>
        );
      },
    },

    {
      field: 'productName',
      headerName: 'Tên sản phẩm',
      flex: 140,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div>
            <div>{params?.row?.productName}</div>
            <div style={{ fontSize: '12px', color: '#b3b3b3' }}>{`(${params?.row?.skuSub})`}</div>
          </div>
        );
      },
    },
    {
      field: 'variations',
      headerName: 'Đặc tính',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div>
            {params?.row?.productVariations.map((item: any, index: number) => {
              return (
                <div>
                  <span key={index} style={{ fontWeight: '600' }}>
                    {item.name}
                  </span>
                  <span key={index}>: {item.value}</span>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'price',
      headerName: 'Giá bán',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return <div>{convertCurrency(params?.row?.price)}</div>;
      },
    },
    {
      field: 'quantity',
      headerName: 'Số lượng',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },

    {
      field: 'staffName',
      headerName: 'Nhân viên',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'note',
      headerName: 'Ghi chú',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Nhận hàng',
      flex: 80,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
            style={{
              border: '1px solid #1797be',
              padding: '0.2rem 0.7rem',
            }}
            onClick={() => onApprove(params?.row?.id, params?.row?.branch, user?.userName)}
          >
            <span style={{ color: '#1797be', textTransform: 'none' }}>Nhập kho</span>
          </Button>
        );
      },
    },
  ];

  return (
    <DataTableV2
      rows={data}
      columns={columns}
      {...props}
      handleSelectAllClick={handleSelectionModeChange}
      handleClick={handleClick}
      selected={selectionModel}
      handleShowDetail={handleShowDetail}
      handleChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      total={total}
      page={page}
      pageSize={pageSize}
      handleInPreview={handleInPreview}
    />
  );
};

export default RefundList;
