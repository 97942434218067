import { Button, Divider, Grid, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { deleteType, getConfigBrand, getType, updateType } from 'src/services/app.services';
import ModalAlert from 'src/shared-components/Modal';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
import BrandList from '../editBrand/components/brandList';
import TypeCreateEdit from './components/typeCreateEdit';
import TypeProductList from './components/typeList';
import './index.scss';

const TypeProduct = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [showDrawer, setShowDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDeleteBrand, setOpenModalDeleteBrand] = useState(false);

  const [typeProduct, setTypeProduct] = useState<any>(null);
  const [idDelete, setIdDelete] = useState<any>(null);
  const [formType, setFormType] = useState<any>(null);
  const [selectedSku, setSelectedSku] = useState<any>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [searchValue, setSearchValue] = useState<any>('');
  const [typeText, setTypeText] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterParams, setFilterParams] = useState<any>(null);
  const [value, setValueChange] = useState<any>(0);

  const debouncedSearch = useDebounce(searchValue, 500);

  const { data: dataType, refetch } = useQuery(['type'], () => getType({}));
  const { data: configBrand, refetch: refetchBrand } = useQuery(['configBrand'], () => getConfigBrand({}));

  const mutationUpdateType = useMutation({
    mutationFn: updateType,
    onSuccess: (data: any) => {
      toast('success', 'Cập nhật thành công');
      setOpenModal(false);
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
      setOpenModal(false);
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationDeleteType = useMutation({
    mutationFn: deleteType,
    onSuccess: (data: any) => {
      toast('success', 'Xóa thành công');
      setOpenModal(false);
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
      setOpenModal(false);
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch });
  }, [debouncedSearch]);

  useEffect(() => {
    if (!showDrawer) {
      setTypeProduct('');
      setFormType('');
    }
  }, [showDrawer]);

  const handelShowDetail = (data: string) => {
    setShowDrawer(true);
    setTypeProduct(data);
    setFormType('update');
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseModalBrand = () => {
    setOpenModalDeleteBrand(false);
  };
  const handleOpenModal = (data: any) => {
    setOpenModal(true);
    setIdDelete(data);
  };
  const handleOpenModalDeleteBrand = (data: any) => {
    setOpenModalDeleteBrand(true);
    setIdDelete(data);
  };

  const onDeleteType = () => {
    const params = {
      code: idDelete?.code,
    };
    mutationDeleteType.mutate(params);
  };
  const onDeleteBrand = () => {
    const params = {
      code: idDelete?.code,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValueChange(newValue);
  };

  const buildTab = () => {
    return (
      <Grid container overflow={'auto'}>
        <Grid flexDirection={{ xs: 'column', md: 'row' }} md={7} item>
          <Tabs value={value} onChange={handleChange} textColor="primary">
            <Tab label="Loại sản phẩm" className="left-border" />
            <Tab label="Thương hiệu" className="mid-border" />
          </Tabs>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={'page-layout'}>
      <Grid container className={'align-items-center header_title'}>
        <Grid item md={6}>
          {buildTab()}
        </Grid>
        {value === 0 && (
          <Grid item md={6}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }} flexDirection={{ xs: 'column', md: 'row' }}>
              <Button onClick={() => setShowDrawer(true)} className={'btn-create'}>
                <span className={'txt'}>Thêm mới loại</span>
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid container className="search"></Grid>
      <div className="container-table-type">
        {value === 0 ? (
          <TypeProductList
            isPagination={false}
            data={dataType?.data || []}
            onShowDetail={handelShowDetail}
            handleChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            total={dataType?.data?.length}
            page={page}
            pageSize={pageSize}
            handleOpenModal={handleOpenModal}
          />
        ) : (
          <BrandList
            isPagination={false}
            data={configBrand?.data || []}
            onShowDetail={handelShowDetail}
            handleChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            total={configBrand?.data?.length}
            page={page}
            pageSize={pageSize}
            refetch={refetchBrand}
            handleOpenModal={handleOpenModalDeleteBrand}
          />
        )}
      </div>
      <TypeCreateEdit
        data={typeProduct}
        type={formType}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
        refetch={refetch}
      />
      <ModalAlert
        open={openModal}
        handleClose={() => handleCloseModal()}
        handleCancel={() => handleCloseModal()}
        handleConfirm={() => onDeleteType()}
        title={''}
        description={''}
        icon={true}
        renderContentModal={
          <div className="view-input-discount">
            <span> Xóa loại sản phẩm {idDelete?.value}</span>
          </div>
        }
      />
      <ModalAlert
        open={openModalDeleteBrand}
        handleClose={() => handleCloseModalBrand()}
        handleCancel={() => handleCloseModalBrand()}
        handleConfirm={() => onDeleteBrand()}
        title={''}
        description={''}
        icon={true}
        renderContentModal={
          <div className="view-input-discount">
            <span> Xóa thương hiệu sản phẩm {idDelete?.value}</span>
          </div>
        }
      />
    </div>
  );
};

export default TypeProduct;
