import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Drawer, Grid } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';

import { LoadingButton } from '@mui/lab';
import { getDelivery } from 'src/services/app.services';
import { updateOrderRefund } from 'src/services/order.servies';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useToast } from 'src/shared-components/Toast';
import { branchData } from 'src/utils/app-data';
import { branchName } from 'src/utils/groups';
import { currencyMark, numberFormat } from 'src/utils/utils';
import '../../../layout/defaultLayout.scss';
import '../order.scss';

interface ICreateProps {
  setShowDrawer: (data: any | undefined) => void;
  showDrawer: boolean | undefined;
  data: any;
  address?: any;
  refetch: any;
  refundFetch?: any;
}
const UpdateTrackingRefund = (props: ICreateProps) => {
  const { setShowDrawer, showDrawer, data, refetch, refundFetch } = props;
  const toast = useToast();

  const schema = yup.object({
    reasonRefund: yup.string().typeError('Lý do không được phép bỏ trống').required('Lý do không được phép bỏ trống'),
    refundFee: yup.string().required('Vui lòng nhập phí khách hàng trả'),
    deliveryTrucking: yup.string().required('Vui lòng nhập mã vận đơn'),
    deliveryId: yup
      .object({
        name: yup.string(),
        id: yup.string(),
      })
      .typeError('Vui lòng chọn đơn vị')
      .required('Vui lòng chọn đơn vị'),
    branch: yup
      .object({
        name: yup.string(),
        codeName: yup.string(),
      })
      .typeError('Vui lòng chọn chi nhánh')
      .required('Vui lòng chọn chi nhánh'),
    note: yup.string(),
  });

  const methods = useForm<yup.InferType<typeof schema>>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, formState, setValue, watch, control } = methods;
  const { errors } = formState;
  const watchRefundFee = watch('refundFee');

  const { data: deliveryData } = useQuery(['delivery'], () => getDelivery({}));
  const { mutate: updateOrderRefundMutation, isLoading: isUpdatingOrderRefund } = useMutation({
    mutationFn: updateOrderRefund(data?.id, data?.shipping?.id),
    onSuccess: () => {
      toast('success', 'Cập nhật đơn hoàn hàng thành công');
      setShowDrawer(false);
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message || 'Thất bại');
      }
    },
  });

  useEffect(() => {
    setValue('refundFee', currencyMark(watchRefundFee || '0'));
  }, [watchRefundFee]);

  const goBack = () => {
    setShowDrawer(false);
  };

  const toggleDrawer = (open: any) => () => {
    setShowDrawer(open);
  };

  const onSubmit = (values: yup.InferType<typeof schema>) => {
    updateOrderRefundMutation({
      deliveryTrucking: values.deliveryTrucking,
      note: values.note,
      reason: values.reasonRefund,
      branch: values.branch.codeName,
      deliveryId: values.deliveryId.id,
      refundFee: numberFormat(values.refundFee),
    });
  };

  console.log('data', data);

  useEffect(() => {
    if (data) {
      setValue('branch', {
        name: branchName?.[data?.shipping?.branch as keyof typeof branchName] || '',
        codeName: data?.shipping?.branch || '',
      });
      setValue('deliveryId', { name: data?.shipping?.delivery?.name, id: data?.shipping?.deliveryId });
      setValue('deliveryTrucking', data?.shipping?.deliveryTrucking || '');
      setValue('note', data?.note || '');
      setValue('reasonRefund', data?.shipping?.reason || '');
      setValue('refundFee', currencyMark(data?.deliveryFreeRefund || '0'));
    }
  }, [data]);

  console.log('reason', data?.shipping?.reason);

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Chỉnh sửa đơn hoàn hàng</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Thông tin hoàn hàng</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <Controller
                    control={control}
                    name="reasonRefund"
                    render={({ field }) => (
                      <FormControlCustom label={'Lý do hoàn hàng'} fullWidth>
                        <InputField
                          {...field}
                          placeholder={'Lý do hoàn'}
                          error={Boolean(errors.reasonRefund)}
                          helperText={errors?.reasonRefund?.message as string}
                        />
                      </FormControlCustom>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="refundFee"
                    render={({ field }) => (
                      <FormControlCustom label={'Số tiền hoàn hàng'} fullWidth>
                        <InputField
                          {...field}
                          helperText={errors?.refundFee?.message as string}
                          error={Boolean(errors?.refundFee)}
                        />
                      </FormControlCustom>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="branch"
                    render={({ field }) => (
                      <FormControlCustom label={'Chi nhánh'} fullWidth>
                        <SelectCustom
                          {...field}
                          placeholder={'Chọn chi nhánh'}
                          error={Boolean(errors?.branch)}
                          helperText={errors?.branch?.message as string}
                          options={branchData}
                          onChange={(_, value) => setValue('branch', value)}
                        />
                      </FormControlCustom>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="deliveryTrucking"
                    render={({ field }) => (
                      <FormControlCustom label={'Mã vận đơn'} fullWidth>
                        <InputField
                          {...field}
                          helperText={errors?.deliveryTrucking?.message as string}
                          error={Boolean(errors?.deliveryTrucking)}
                          placeholder={'Nhập mã vận đơn'}
                        />
                      </FormControlCustom>
                    )}
                  />
                </Grid>
                <Grid item xs={6} className="auto-complete">
                  <Controller
                    control={control}
                    name="deliveryId"
                    render={({ field }) => (
                      <FormControlCustom label={'Đơn vị'} fullWidth>
                        <SelectCustom
                          {...field}
                          options={deliveryData?.data}
                          placeholder={'Chọn đơn vị'}
                          error={Boolean(errors?.deliveryId)}
                          helperText={errors?.deliveryId?.message as string}
                          onChange={(_, value) => setValue('deliveryId', { name: value.name, id: value.id })}
                        />
                      </FormControlCustom>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="note"
                    render={({ field }) => (
                      <FormControlCustom label={'Ghi chú'} fullWidth>
                        <InputField
                          {...field}
                          helperText={errors?.note?.message as string}
                          error={Boolean(errors.note)}
                          placeholder={'Nhập ghi chú'}
                        />
                      </FormControlCustom>
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Hủy
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className={'btn-tertiary-normal'} type="submit">
                  {isUpdatingOrderRefund && <LoadingButton loading={true} loadingPosition="start" />}
                  Xác nhận
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default UpdateTrackingRefund;
